/* eslint-disable react/no-array-index-key */
import { lazy, Suspense, Fragment, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "src/layouts/Layout/Layout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import { useAppSelector } from "src/hooks";

interface dropDownItem {
  id: number;
  navName: string;
  link: string;
  name?: JSX.Element;
}
export interface ISIDEBARITEM {
  path: string;
  component?: JSX.Element;
  icon?: string;
  link?: string;
  dropDownItem?: dropDownItem[];
}

const routesConfig = [
  {
    label: "default",
    path: "/",
    component: () => <Navigate to="/dashboard" />,
  },
  {
    label: "default",
    path: "/404",
    component: lazy(() => import("src/components/Error404View")),
  },
  {
    label: "default",
    path: "*",
    component: () => <Navigate to="/404" />,
  },
  {
    label: "default",
    path: "/login",
    guard: GuestGuard,
    component: lazy(
      () => import("src/components/Authentication/Authentication")
    ),
  },
  {
    label: "default",
    path: "/individual-user",
    guard: GuestGuard,
    component: lazy(
      () => import("src/components/Register/IndividualUser/IndividualUser")
    ),
  },
  {
    label: "default",
    path: "/individual-user/account-created-successfully",
    guard: GuestGuard,
    component: lazy(
      () =>
        import(
          "src/components/Register/IndividualUser/AccountCreatedSuccessfully/AccountCreatedSuccessfully"
        )
    ),
  },
  {
    label: "default",
    path: "/individual-user/create-password",
    guard: GuestGuard,
    component: lazy(
      () =>
        import(
          "src/components/Register/IndividualUser/AccountCreatedSuccessfully/IndividualUserCreatePassword/IndividualUserCreatePassword"
        )
    ),
  },
  {
    label: "default",
    path: "/company-user",
    guard: GuestGuard,
    component: lazy(
      () => import("src/components/Register/CompanyUser/CompanyUser")
    ),
  },
  {
    label: "default",
    path: "/company-user/account-created-successfully",
    guard: GuestGuard,
    component: lazy(
      () =>
        import(
          "src/components/Register/CompanyUser/CompanyAccountCreatedSuccessfully/CompanyAccountCreatedSuccessfully"
        )
    ),
  },
  {
    label: "default",
    path: "/company-user/create-password",
    guard: GuestGuard,
    component: lazy(
      () =>
        import(
          "src/components/Register/CompanyUser/CompanyAccountCreatedSuccessfully/CompanyUserCreatePassword/CompanyUserCreatePassword"
        )
    ),
  },
  {
    label: "default",
    path: "/set-password",
    guard: GuestGuard,
    component: lazy(
      () => import("src/components/Register/SetPassword/SetPassword")
    ),
  },
  // Company Admin Routes Start
  {
    label: [
      "dashboard_create_application",
      "dashboard_application_pending_evidence_check",
      "dashboard_incomplete_application",
      "dashboard_rejected_application",
      "dashboard_application_requirement_payment",
      "dashboard_application_already_paid",
      "dashboard_spend_history_check",
      "dashboard_pending_application_dbs_check",
      "dashboard_certificates_eligible_reprint",
    ],
    path: "/dashboard",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/Dashboard/Dashboard")),
  },
  {
    label: "default",
    path: "/change-password",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/Authentication/IndividualUser/ChangePassword/IndividualChangePassword"
        )
    ),
  },
  {
    label: "settings_manage_users",
    path: "/setting/manage-users/create-user",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/Setting/ManageUsers/CreateUser/CreateUser")
    ),
  },
  {
    label: ["application_create", "application_view_assigned_dbs_application"],
    path: `/apply-for/create-application`,
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/CheckDbsApplicationForm/CheckDbsApplicationForm"
        )
    ),
  },
  {
    label: "application_requiring_payment",
    path: "/dbs-applications/dbs-application-requiring-payment",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/DbsApplicationRequiringPayment/DbsApplicationRequiringPayment"
        )
    ),
  },
  {
    label: "application_already_paid",
    path: "/dbs-applications/dbs-application-already-paid-for",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/DbsApplicationAlreadyPaidFor/DbsApplicationAlreadyPaidFor"
        )
    ),
  },
  {
    label: "application_pending",
    path: "/dbs-applications/application-pending-for-dbs",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/ApplicationsPendingForTheDbs/ApplicationsPendingForTheDbs"
        )
    ),
  },

  {
    path: "/dbs-applications/application-pending-for-dbs/dbs-application-review",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/ApplicationsPendingForTheDbs/PendingViewDetail/PendingViewDetail"
        )
    ),
  },
  {
    label: "application_certificates_eligible_reprint",
    path: "/dbs-applications/dbs-certificate-eligible",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/DbsCertificatesEligibleForRepint/DbsCertificatesEligibleForRepint"
        )
    ),
  },
  {
    label: "application_spent_history_check",
    path: "/dbs-applications/spend-history-for-dbs-checks",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/SpendHistoryForYourDbsChecks/SpendHistoryForYourDbsChecks"
        )
    ),
  },
  {
    label: "application_successful_payments_dbs_account",
    path: "/dbs-applications/successful-payment-to-your-dbs-account",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/SuccessfulPaymentToyourDbsAccount/SuccessfulPaymentToyourDbsAccount"
        )
    ),
  },
  {
    label: "apply_for",
    path: "/apply-for",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/ApplyFor/ApplyFor")),
  },
  {
    label: "default",
    // label: "application_review",
    path: "/apply-for/dbs-application-review",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/ApplyFor/DbsApplicationReview/DbsApplicationReview"
        )
    ),
  },
  {
    label: "application_pending_for_ec_applicant",
    path: "/dbs-applications/application-pending-for-ec-applicant",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/ApplicationsPendingECApplicant/ApplicationsPendingECApplicant"
        )
    ),
  },
  {
    label: "application_view_assigned_dbs_application",
    path: "/dbs-applications/view-assigned-dbs-application",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/ViewAssignedDBSApplication/ViewAssignedDBSApplication"
        )
    ),
  },
  {
    label: ["application_pay_or_fix_issues", "application_fix_issues"],
    path: "/dbs-applications/pay-or-fix-issues",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/FixIssuesDbsApplication/FixIssuesDbsApplication"
        )
    ),
  },
  {
    label: "application_incomplete",
    path: "/dbs-applications/incomplete-dbs-appication",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/IncompleteDbsApplication/IncompleteDbsApplication"
        )
    ),
  },
  {
    label: "application_rejected",
    path: "/dbs-applications/rejected-dbs-application",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/RejectedDbsApplication/RejectedDbsApplication"
        )
    ),
  },
  {
    label: "default",
    path: "/dbs-applications/view-dbs-application",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import("src/components/Common/ViewDbsApplication/ViewDbsApplication")
    ),
  },
  {
    path: "/dbs-applications/add-adult-first-check",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/AddAdultFirstCheckCompanyAdmin/AddAdultFirstCheckCompanyAdmin"
        )
    ),
  },
  {
    path: "/dbs-applications/add-adult-first-check/view-dbs-application",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/AddAdultFirstCheckCompanyAdmin/ViewDbsApplication/ViewDbsApplication"
        )
    ),
  },
  {
    label: "application_pending_evidence_check",
    path: "/dbs-applications/application-pending-evidence-check",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/ApplicationPendingEvidenceCheck/ApplicationPending"
        )
    ),
  },
  {
    label: "application_completed_dbs_checks_certificate_posted_out",
    path: "/dbs-applications/completed-dbs-appication",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/DbsApplications/CompletedDbsApplication/CompletedDbsApplication"
        )
    ),
  },

  {
    label: "settings_evidence_checker",
    path: "/setting/evidence-checkers",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import("src/components/Setting/EvidenceChecker/SettingEvidenceChecker")
    ),
  },
  {
    label: "settings_manage_email_template",
    path: "/setting/manage-email-templates",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/Setting/ManageEmailTemplates/ManageEmailTemplates"
        )
    ),
  },
  {
    label: "settings_edit_company_details",
    path: "/setting/edit-company",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/Setting/EditCompany/EditCompany")
    ),
  },
  {
    label: "settings_manage_users",
    path: "/setting/manage-users",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/Setting/ManageUsers/ManageUser")
    ),
  },
  {
    label: "settings_manage_dashboard",
    path: "/setting/dashboards",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/Setting/Dashboards/Dashboards")
    ),
  },
  {
    label: "accounts_invoices",
    path: "/my-accounts/invoices",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/MyAccounts/Invoices/Invoices")
    ),
  },
  {
    label: "accounts_adjustment",
    path: "/my-accounts/adjustment",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/MyAccounts/Adjustment/Adjustment")
    ),
  },
  {
    label: "accounts_transactions",
    path: "/my-accounts/transactions",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/MyAccounts/Transactions/Transactions")
    ),
  },
  {
    label: "accounts_payments",
    path: "/my-accounts/payments",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/MyAccounts/Payments/Payments")
    ),
  },
  {
    label: "accounts_refunds",
    path: "/my-accounts/refunds",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/MyAccounts/Refunds/Refunds")),
  },
  {
    label: "accounts_spent",
    path: "/my-accounts/spent",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/MyAccounts/Spent/Spent")),
  },
  {
    label: "accounts_user_spent",
    path: "/my-accounts/user-spent",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/MyAccounts/UserSpent/UserSpent")
    ),
  },
  {
    label: "accounts_balance_details",
    path: "/my-accounts/balance-details",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/MyAccounts/BalanceDetails/BalanceDetails")
    ),
  },
  {
    label: "training_add_details",
    path: "/training",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/Training/Training")),
  },
  {
    path: "/report",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/Report/Report")),
  },
  {
    label: "default",
    path: "/help",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/Help/Help")),
  },
  {
    label: "default",
    path: "/user-manual",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/UserManual/UserManual")),
  },
  {
    label: "accounts_adjustment",
    path: "/my-accounts/adjustment/AdjustDbsFeeToAdminFee",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/MyAccounts/Adjustment/AdjustDbsFeeToAdminFee/AdjustDbsFeeToAdminFee"
        )
    ),
  },
  {
    label: "accounts_adjustment",
    path: "/my-accounts/adjustment/AdjustAdminFeeToDbsFee",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/MyAccounts/Adjustment/AdjustAdminFeeToDbsFee/AdjustAdminFeeToDbsFee"
        )
    ),
  },

  // End of company Admin Routes
  {
    label: "application_post_comment",
    path: "/post-comment-application",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/CounterSignatory/PostCommentOnApplication/CounterSignatory/PostCommentOnApplication"
        )
    ),
  },
  {
    label: "dashboard_total_assigned_dbs_application",
    path: "/counter-signatory/reviewed-applications",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/CounterSignatory/ReviewedApplications/ReviewedApplications"
        )
    ),
  },
  {
    label: "dashboard_total_assigned_dbs_application",
    path: "/counter-signatory/reviewed-applications/company-view-details",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/CounterSignatory/ReviewedApplications/ReviewedApplicationsTabsData/Companies/ViewDetails"
        )
    ),
  },
  {
    path: "/counter-signatory/reviewed-applications/individual-view-details",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/CounterSignatory/ReviewedApplications/ReviewedApplicationsTabsData/IndividualApplicants/ViewDetails"
        )
    ),
  },
  // Counter Signatory Dashboard Paths (Start)
  {
    path: "/counter-signatory",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/Dashboard/CounterSignatoryDashboard/CounterSignatoryDashboard"
        )
    ),
  },
  // Counter Signatory Dashboard Paths (End)

  // System Admin Dashboard Paths (start)

  {
    label: [
      "dashboard_manage_users",
      "dashboard_manage_third_party_licenses",
      "dashboard_connected_devices",
      "dashboard_available_server_space",
      "dashboard_smd_traffic_monitoring",
      "dashboard_memory_usage",
      "dashboard_audit_trail",
      "dashboard_backup_achieve",
      "dashboard_reminders",
      "dashboard_recent_activities",
      "dashboard_active_sessions",
    ],
    path: "/system-admin",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/Dashboard/SystemAdmin/SystemAdmin")
    ),
  },
  {
    label: "manage_user_user_type",
    path: "/manage-users",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/ManageUsers/ManageUsers")),
  },
  {
    label: "manage_user_user_type",
    path: "/manage-users/company-admin",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/ManageUsers/CompanyAdmin/CompanyAdmin")
    ),
  },
  {
    label: "manage_user_user_type",
    path: "manage-users/evidence-checker",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/ManageUsers/EvidenceChecker/EvidenceChecker")
    ),
  },
  {
    label: "manage_user_user_type",
    path: "manage-users/counter-signatory",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import("src/components/ManageUsers/CounterSignatory/CounterSignatory")
    ),
  },
  {
    label: "manage_user_user_type",
    path: "manage-users/company-admin-evidence-checker",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () =>
        import(
          "src/components/ManageUsers/CompanyAdminEvidenceChecker/CompanyAdminEvidenceChecker"
        )
    ),
  },
  {
    label: "manage_user_user_type",
    path: "manage-users/individual-user",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/ManageUsers/IndividualUser/IndividualUser")
    ),
  },
  {
    label: "manage_user_user_type",
    path: "manage-users/system-admin",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/ManageUsers/SystemAdmin/SystemAdmin")
    ),
  },
  {
    label: [
      "API_inventory_list",
      "API_inventory_add_new",
      "API_inventory_filter",
    ],
    path: "/api-inventory",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/ApiInventory/ApiInventory")),
  },
  {
    label: [
      "system_performance_disk_usage",
      "system_performance_server_downtime",
      "system_performance_memory_utilization",
    ],
    path: "/system-performance",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/SystemPerformance/SystemPerformance")
    ),
  },
  {
    path: "/back-up",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/BackUp/BackUp")),
  },
  {
    label: [
      "manage_notification_add_notification",
      "manage_notification_edit_notification",
      "manage_notification_delete_notification",
    ],
    path: "/manage-notification",
    guard: GuestGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/Managenotification/Managenotification")
    ),
  },
  {
    path: "/audit-logs",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/components/AduitLogs/AduitLogs")),
    label: "audit_log_view",
  },
  {
    path: "/ratings-and-feedback",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(
      () => import("src/components/RatingsFeedback/RatingsFeedback")
    ),
  },
  {
    path: "/access-denied",
    guard: AuthGuard,
    layout: Layout,
    component: lazy(() => import("src/ui/AccessDenied/AccessDenied")),
  },
  // System Admin Dashboard Paths (End)
];

const renderRoutes = (routes: any) =>
  routes && (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route: any, i: number) => {
          const Guard = route?.guard || Fragment;
          const Layout = route?.layout || Fragment;
          const Component = route?.component;

          return (
            <Route
              key={i}
              path={route?.path}
              element={
                <Guard>
                  <Layout>
                    <Component />
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );

function CustomRoutes() {
  const { permissions } = useAppSelector(
    (store: { permissions: any }) => store?.permissions
  );
  const [allowedPermission, setAllowedPermission] = useState<any>([]);

  useEffect(() => {
    let permissionArray = routesConfig.filter(
      (item: any) =>
        item.label === "default" ||
        (permissions?.length > 0 && permissions?.includes(item?.label)) ||
        (Array.isArray(item?.label)
          ? item?.label.map((arr: any) => permissions?.includes(arr))
          : null)
    );
    setAllowedPermission(permissionArray);
  }, [permissions]);

  return renderRoutes(allowedPermission);
}

export default CustomRoutes;
