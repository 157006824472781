// Font Families
export const POPPINS = "Poppins";
export const ROBOTO = "Roboto";

export const MUIPRIMARYCOLOR = "#19456a";
export const MUISECONDARYCOLOR = "#1dc99c";

export const MUIAPP = "#f8fcff";
export const MUIBLACKCOLOR = "#000000";
export const MUIWHITECOLOR = "#ffffff";
export const MUIBLACKPEARL = "#1a141f";
export const MUIDARKGRAYISHVIOLET = "#625e66";
export const MUIMAYABLUE = "rgba(126, 193, 250, 0.11)";
export const MUIMAYAGREEN = "rgba(29, 201, 156, 0.1)";
export const MUITABS = "rgba(39, 112, 173, 0.1)";
export const MUISEARCHFIELDBORDER = "rgba(25, 69, 106, 0.2)";
export const MUICHATHAMSBLUE = "#145388";

export const MUIFRUITSALAD = "#4caf50";
export const MUIDARKWASHEDRED = "#ff3333";
export const MUICYANBLUE = "#439ae2";
export const MUIREDDISHWHITESMOKE = "#feefef";
export const MUIPALATINATEBLUE = "#2639ed";
export const MUILIGHTORANGE = "#ff9900";
export const MUISAFETYBLUE = "#6e00fa";
export const MUITOASTER = "#e1f6e7";
export const MUISABMARINE = "#9d9e9e";
export const MUIGRAY92 = "#ebebeb";

export const MUIPRIMARYHEADING = "32px";
export const MUISECONDARYHEADING = "24px";
export const MUITERTIARYHEADING = "28px";
export const MUIHEADING48 = "48px";
export const MUIHEADING42 = "42px";
export const MUIHEADING20 = "20px";
export const MUIHEADING22 = "22px";
export const MUIHEADING18 = "18px";
export const MUIPRIMARYTITLE = "16px";
export const MUISECONDARYTITLE = "14px";
export const MUITERTIARYTITLE = "12px";
export const MUITITLE10 = "10px";

export const MUIBORDERRADIUS4 = "4px";
export const MUIBORDERRADIUS8 = "8px";
export const MUIBORDERRADIUS22 = "22px";
export const MUIBORDERRADIUSHEADERTABS1 = "0px 4px 4px 0px";
export const MUIBORDERRADIUSHEADERTABS2 = "8px 0px 0px 0px";

export const MUIFONTWEIGHT300 = "300";
export const MUIFONTWEIGHT400 = "400";
export const MUIFONTWEIGHT500 = "500";
export const MUIFONTWEIGHT600 = "600";
export const MUIFONTWEIGHT700 = "700";
export const MUIFONTWEIGHT800 = "800";
export const MUIFONTWEIGHT900 = "900";

// Gradients
export const GRADIANTGREEN =
  "linear-gradient(180deg, #20c27e 0%, #18b74e 100%)";
export const GRADIANTBLUE =
  "linear-gradient(92.12deg, #145388 -6.21%, #439ae2 116.93%)";
export const GRADIANTBLUEHOVER =
  "linear-gradient(92.12deg, #0e385a -6.21%, #2b628f 116.93%)";

// Shadows
export const BIGSHADOW = "inset 0px 0px 8px rgba(193, 227, 255, 0.47)";
export const SMALLSHADOW = "0px 0px 0px rgba(61, 40, 216, 0.1)";
export const SCROLLSHADOW = "inset 0 0 5px grey";
