import { FC, useState } from "react";
import TextField from "@mui/material/TextField";
import { ErrorMessage, useField, useFormikContext } from "formik";
import infoerror from "src/assets/register/infoerror.svg";
import { Box } from "@mui/system";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import moment from "moment";

interface formProps {
  label: any;
  icon?: any;
  name: string;
  type?: string;
  hint?: string;
  InputLabelProps?: any;
  value?: any;
  size?: any;
  disabled?: boolean;
  rows?: number;
  multiline?: boolean;
  required?: boolean;
  shrink?: boolean;
  onClick?: any;
  sx?: any;
  EndIcon?: any;
  StartIcon?: any;
  variant?: "outlined" | "standard" | "filled" | undefined;
  minDate?: any;
  maxDate?: any;
  placeholder?: string;
  readOnly?: boolean;
  enablePerformance?: boolean
}

export const TextFieldWrapper: FC<formProps> = ({
  hint,
  icon,
  type,
  EndIcon = null,
  StartIcon = null,
  variant = "outlined",
  maxDate = moment(Date.now()).format("YYYY-MM-DD"),
  minDate = null,
  readOnly = false,
  sx,
  enablePerformance = false,
  ...props
}) => {
  const [fieldValue, setFieldValue] = useState("")
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);


  return (
    <Box>
      <TextField
        {...field}
        {...props}
        sx={sx}
        fullWidth
        variant={variant}
        error={!!(meta.touched && meta.error)}
        autoComplete="off"
        type={showPassword ? "text" : type}
        inputProps={{ max: maxDate, min: minDate }}
        InputProps={{
          readOnly: readOnly,
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  sx={{ color: "secondary.main" }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : (
              EndIcon
            ),
          startAdornment: StartIcon,
        }}
      />
      <Box sx={{display:"flex",gap:"5px",flexWrap:"wrap"}} >
        {hint && (
          <Box  sx={{mr:"auto"}}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "text.secondary",
                fontStyle: "italic",
                mt: 0.8,
              }}
            >
              {hint}
            </Typography>
          </Box>
        )}
        {meta.touched && meta.error ? (
          <Box
            sx={{
              display:"flex",
              ml:"auto",
             

            }}
          >
            <ErrorMessage
              component="div"
              
              name={field?.name}
              className="error font-size-12 font-weight-500 dark-washed-red font-family-poppins"
            />
            <Box sx={{ ml: 1 }}>
              <img src={infoerror} alt="info" />
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
