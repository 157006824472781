import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
} from "react";
import Modal from "@mui/material/Modal";
import { Box, Grid } from "@mui/material";
import Header from "./SubComponenet/Header";
import EditForm from "./SubComponenet/EditForm";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { profileDataIndividualGet } from "src/store";

interface IMODAL {
  setOpenViewModal: Dispatch<SetStateAction<boolean>>;
  openViewModal: boolean;
}

const IndividualEditProfile: FC<IMODAL> = ({
  setOpenViewModal,
  openViewModal,
}) => {
  const { profileData } = useAppSelector((store) => store.profile);
  const [showButton, setShowButton] = useState<Boolean>(false);
  const dispatch = useAppDispatch();

  const getProfileData = useCallback(async () => {
    try {
      await dispatch(profileDataIndividualGet());
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  return (
    <Modal
      open={openViewModal}
      onClose={() => {
        setOpenViewModal(false);
        setShowButton(false);
      }}
    >
      <Grid
        container
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        height={"90%"}
        width={"85%"}
        boxShadow={3}
        sx={{
          backgroundColor: "common.white",
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
        }}
      >
        <Box width={"100%"}>
          <Header
            profileName={profileData?.foreName + " " + profileData?.surName}
            userType={profileData?.userType}
            setOpen={setOpenViewModal}
          />
          <EditForm
            setOpen={setOpenViewModal}
            setShowButton={setShowButton}
            showButton={showButton}
          />
        </Box>
      </Grid>
    </Modal>
  );
};

export default IndividualEditProfile;
