import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetRequest } from "src/helpers";
import { endpoints } from "src/config";

export const getPermissions = createAsyncThunk(
  "company/getUserPermissions",
  async () => {
    return await apiGetRequest(endpoints.getUserPermissions).then((res) =>
      Promise.resolve(res)
    );
  }
);
