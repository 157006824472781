import { createSlice } from "@reduxjs/toolkit";
import { profileInitialStateTypes } from "./profile.types";
import { REQUEST_STATUS } from "src/constants/request-status";
import {
  profileDataCompanyGet,
  profileDataIndividualGet,
  profileDataIndividualPost,
  profileDataCompanyPost,
  profilePicGet,
  profilePicPost,
  profilePicPatch,
} from "./profile.api";

export const profileInitialState: profileInitialStateTypes = {
  profileData: {},
  profileImage: "",
  getProfileDataRequestStatus: REQUEST_STATUS?.IDEL,
  postProfileDataRequestStatus: REQUEST_STATUS?.IDEL,
  getProfilePicRequestStatus: REQUEST_STATUS?.IDEL,
  postProfilePicRequestStatus: REQUEST_STATUS?.IDEL,
  patchProfilePicRequestStatus: REQUEST_STATUS?.IDEL,
};

const dataMapper = (data: any) => {
  return {
    ...data.companyAdminUser,
    ...data.companyDetails,
    ...data.yourDetails,
  };
};

const profile = createSlice({
  name: "profile",
  initialState: profileInitialState,
  reducers: {},

  extraReducers: (builder) => {
    // START GET PROFILE COMPANY PANEL
    builder.addCase(profileDataCompanyGet.pending, (state, action) => {
      state.getProfileDataRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(profileDataCompanyGet.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.profileData = data;
      state.getProfileDataRequestStatus = REQUEST_STATUS.SUCCESS;
    });
    builder.addCase(profileDataCompanyGet.rejected, (state, error) => {
      state.getProfileDataRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END GET PROFILE COMPANY PANEL

    // START POST PROFILE COMPANY PANEL
    builder.addCase(profileDataCompanyPost.pending, (state, action) => {
      state.postProfileDataRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(profileDataCompanyPost.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.profileData = dataMapper(data);
      state.postProfileDataRequestStatus = REQUEST_STATUS.SUCCESS;
    });
    builder.addCase(profileDataCompanyPost.rejected, (state, error) => {
      state.postProfileDataRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END POST PROFILE COMPANY PANEL

    // START GET PROFILE INDIVIDUAL PANEL
    builder.addCase(profileDataIndividualGet.pending, (state, action) => {
      state.getProfileDataRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(
      profileDataIndividualGet.fulfilled,
      (state, action: any) => {
        const { data } = action?.payload;
        state.profileData = data;
        state.getProfileDataRequestStatus = REQUEST_STATUS.SUCCESS;
      }
    );
    builder.addCase(profileDataIndividualGet.rejected, (state, error) => {
      state.getProfileDataRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END GET PROFILE INDIVIDUAL PANEL

    // START POST PROFILE INDIVIDUAL PANEL
    builder.addCase(profileDataIndividualPost.pending, (state, action) => {
      state.postProfileDataRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(
      profileDataIndividualPost.fulfilled,
      (state, action: any) => {
        const { data } = action?.payload;
        state.profileData = data?.user;
        state.postProfileDataRequestStatus = REQUEST_STATUS.SUCCESS;
      }
    );
    builder.addCase(profileDataIndividualPost.rejected, (state, error) => {
      state.postProfileDataRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END POST PROFILE INDIVIDUAL PANEL

    // START GET PROFILE PIC PANEL
    builder.addCase(profilePicGet.pending, (state, action) => {
      state.getProfilePicRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(profilePicGet.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.getProfilePicRequestStatus = REQUEST_STATUS.SUCCESS;
      state.profileImage = data?.profilePic;
    });
    builder.addCase(profilePicGet.rejected, (state, error) => {
      state.getProfilePicRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END GET PROFILE PIC PANEL

    // START POST PROFILE PIC PANEL
    builder.addCase(profilePicPost.pending, (state, action) => {
      state.postProfilePicRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(profilePicPost.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.postProfilePicRequestStatus = REQUEST_STATUS.SUCCESS;
      state.profileImage = data?.data?.profilePic;
    });
    builder.addCase(profilePicPost.rejected, (state, error) => {
      state.postProfilePicRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END POST PROFILE PIC PANEL

    // START PATCH PROFILE PIC PANEL
    builder.addCase(profilePicPatch.pending, (state, action) => {
      state.patchProfilePicRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(profilePicPatch.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.patchProfilePicRequestStatus = REQUEST_STATUS.SUCCESS;
      state.profileImage = data?.data?.profilePic;
    });
    builder.addCase(profilePicPatch.rejected, (state, error) => {
      state.patchProfilePicRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END PATCH PROFILE PIC PANEL
  },
});

export const profileActions = profile.actions;
export const profileReducer = profile.reducer;
