import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import LeftSidebar from "./LeftSidebar/LeftSidebar";
import "./Layout.scss";
import { Box } from "@mui/material";
import { useAppSelector } from "src/hooks";
import AdminHeader from "./AdminHeader/AdminHeader";

const Layout: React.FC = ({ children }: any) => {
  const [sidebarToggler, setSidebarToggler] = useState<any>(true);
  const [activeItem, setActiveItem] = React.useState<boolean>(false);
  const { userRole } = useAppSelector((store) => store.auth);

  const sidebarTogglerHandler = () => {
    setActiveItem(false);
    setSidebarToggler(!sidebarToggler);
  };
  const setActiveItemList = (val: any) => {
    setActiveItem(val);
  };

  return (
    <Box className="layout" sx={{ background: "#f8fcff" }}>
      <Box className="layout-inner">
        <Box className="layout-sidebar">
          <LeftSidebar
            setSidebarToggler={setSidebarToggler}
            sidebarToggler={sidebarToggler}
            sidebarTogglerHandler={sidebarTogglerHandler}
            activeItem={activeItem}
            setActiveItemList={setActiveItemList}
          />
        </Box>
        <Box
          className={sidebarToggler ? "layout-content" : "layout-content-close"}
        >
          {userRole === "SYSTEM_ADMIN" ? <AdminHeader /> : <Header />}
          <Box
            className={`outlet ${
              userRole === "SYSTEM_ADMIN" && "outlet-system-admin"
            }`}
          >
            {children}
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Box className="layout-footer">
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
