import { Box, Typography, Grid } from "@mui/material";
import { useAppSelector } from "src/hooks";
import { GenderData } from "src/mock-data/ViewProfile/ViewProfile";
import SelectWrapper from "src/ui/FormsInput/SelectWrapper";
import { TextFieldWrapper } from "src/ui/FormsInput/TextFieldWrapper";

const YourDetails = ({ setShowButton }: any) => {
  // const { userRole } = useAppSelector((store) => store.auth);
  // let isDisabled = userRole !== "COMPANY_ADMIN";

  return (
    <>
      <Typography variant="h1">Your Details</Typography>
      <Grid container spacing={2} mt={2} rowGap={2}>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Forename
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="yourDetails.foreName"
            type="text"
            // disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Surname
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="yourDetails.surName"
            type="text"
            // disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectWrapper
            onClick={() => setShowButton(true)}
            label="Gender"
            options={GenderData}
            name="yourDetails.gender"
            // disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default YourDetails;
