import { Box, CircularProgress } from "@mui/material";
import Logo from "./Logo";
import { makeStyles } from "@mui/styles";
import checkmydbsblue from "src/assets/tooltipIcons/checkmydbsblue.svg";
const useStyles = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    // backgroundColor: theme?.palette?.background?.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    // padding: theme?.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
  logo: {
    width: 200,
    maxWidth: "100%",
  },
}));

function SlashScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <img src={checkmydbsblue} alt="" />
      
      </Box>
      <CircularProgress />
    </div>
  );
}

export default SlashScreen;
