/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { colors } from "@mui/material";
import { typography } from "./typography";
import { shadows } from "./shadows";
import { THEMES } from "src/constants/theme-constant";

const baseConfig = {
  direction: "ltr",
  typography,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.075)",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#1dc99c",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          "&.Mui-selected": {
            backgroundColor: "#ddf3ed",
            color: "#1A141F",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#EDFFFA",
          },
        },
      },
    },
  },
};

const themeConfigs = [
  {
    name: THEMES.ONE_DARK,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      mode: "light",
      common: {
        black: "#000",
        white: "#fff",
      },
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "#68b6a1",
        hoverOpacity: 0.04,
        selected: "rgba(255, 255, 255, 0.08)",
        selectedOpacity: 0.08,
        // disabled: "#fff",
        disabledOpacity: 0.38,
        // disabledBackground: "#828685",
        focus: "rgba(255, 255, 255, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
        activeChannel: "0 0 0",
      },
      background: {
        default: "#f8fcff",
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#1dc99c",
        light: "#68b6a1",
        dark: "#00c28e",
        contrastText: "#fff",
      },
      secondary: {
        main: "#19456a",
      },
      error: {
        main: "#ff3333",
        light: "#feefef",
      },
      warning: {
        main: "#ed6c02",
      },
      success: {
        main: "#4caf50",
        light: "#e1f6e7",
      },
      info: {
        main: "#0288d1",
      },
      text: {
        primary: "#1A141F",
        secondary: "#9D9E9E",
        disabled: "rgba(0,0,0,0.38)",
      },
      divider: "rgba(0,0,0,0.12)",
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#625E66",
        900: "#6C757D",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161",
      },
    },
    shape: {
      borderRadius: 8,
    },
    shadows,
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 1000,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
      easing: {
        // This is the most common easing curve.
        easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
        // Objects enter the screen at full velocity from off-screen and
        // slowly decelerate to a resting point.
        easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
        // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        easeIn: "cubic-bezier(0.4, 0, 1, 1)",
        // The sharp curve is used by objects that may return to the screen at any time.
        sharp: "cubic-bezier(0,0,1,1)",
      },
    },
  },
];

export function createCustomTheme(settings: any) {
  let themeConfig: any = themeConfigs.find(
    (theme) => theme.name === settings.theme
  );

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
