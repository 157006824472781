import React from "react";
import "./Footer.scss";
import {
  FooterData,
  FooterTermAndCondtion,
} from "src/mock-data/layout/LayoutData";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import {
  MUIFONTWEIGHT600,
  MUISECONDARYTITLE,
} from "src/constants/mui-styles-constant";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <Box
        className="footer-layout"
        sx={{
          fontSize: MUISECONDARYTITLE,
          color: "grey.900",
          display: "flex",
          alignItems: "center",
          backgroundColor: "common.white",
          p: "0 8px",
          lineHeight: "30px",
          mt: "2vh",
          minHeight: "5vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{
              textAlign: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "left",
              },
            }}
          >
            <Box>
              Copyright ©{currentYear} All rights reserved by
              <Typography
                variant="body2"
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  color: "primary.main",
                  marginLeft: "5px",
                }}
              >
                Orcalo Limited
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            lg={4}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "left",
                md: "left",
                lg: "center",
              },
            }}
          >
            {FooterData.map((item) => (
              <Box key={item.id} sx={{ p: "0 8px" }}>
                <img src={item.icon} alt="" style={{ width: "20px" }} />
              </Box>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            sx={{
              justifyContent: { xs: "center", sm: "right", md: "right" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row", md: "row" },
              alignItems: { xs: "center" },
            }}
          >
            {FooterTermAndCondtion.map((item) => (
              <Box
                className="font-weight-600"
                sx={{
                  p: "0 10px",
                  cursor: "pointer",
                  fontWeight: MUIFONTWEIGHT600,
                }}
                key={item.id}
              >
                {item.name}{" "}
                <Box sx={{ display: "inline", paddingLeft: "12px" }}>
                  {item.separator}
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
