import ReactDOM from "react-dom/client";
import "src/style/_variables.scss";
import "src/style/common.scss";
import App from "src/App";
import { SettingsProvider } from "src/context/settings-context";
import { restoreSettings } from "src/utils/settings";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { createStore } from "src/store";

const store = createStore;
const persistor = persistStore(store);

const settings = restoreSettings();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </PersistGate>
  </Provider>
  //</React.StrictMode>
);
