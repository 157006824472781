import { createSlice } from "@reduxjs/toolkit";
import { IInitialStateTypes } from "./company-admin.types";

export const initialState: IInitialStateTypes | any = {
  addModalOpener: false,
  editModalOpener: false,
  resetModalOpener: false,
  viewModalOpener: false,
  rolesEditModalOpener: false,
  tabsValue: "1",
};

const companyAdminSlice = createSlice({
  name: "companyAdmin",
  initialState,
  reducers: {
    setManageUserManageAdmin: (state, action) => {
      state.manageUserManageAdmin = action.payload;
    },
    setManageUserRolesAndRights: (state, action) => {
      state.manageUserRolesAndRights = action.payload;
    },
    setAddModalOpener: (state, action) => {
      state.addModalOpener = action.payload;
    },
    setEditModalOpener: (state, action) => {
      state.editModalOpener = action.payload;
    },
    setResetModalOpener: (state, action) => {
      state.resetModalOpener = action.payload;
    },
    setViewModalOpener: (state, action) => {
      state.viewModalOpener = action.payload;
    },
    setRolesEditModalOpener: (state, action) => {
      state.rolesEditModalOpener = action.payload;
    },
    setTabsValue: (state, action) => {
      state.tabsValue = action.payload;
    },
  },
});

export const companyAdminActions = companyAdminSlice.actions;
export const companyAdminReducer = companyAdminSlice.reducer;
