/**
 * @file Contains the Applications slice.
 * Here the slice is initialized.
 */

import { createSlice } from "@reduxjs/toolkit";
import { DbsAppInitial } from "./dbs-applications.types";

export const dbsAppInitial: DbsAppInitial = {
    tabValue: 0,
    manualSubmit: -1
}

const dbsAppSlice = createSlice({
    name: "dbsApps",
    initialState: dbsAppInitial,
    reducers: {
        updateTabValue: (state, action) => {
            state.tabValue = action.payload
        },
        updateManualSubmit: (state, action) => {
            state.manualSubmit = action.payload
        }
    }
});

export const dbsAppActions = dbsAppSlice.actions;
export const dbsAppReducer = dbsAppSlice.reducer;
