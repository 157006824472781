import { createSlice } from "@reduxjs/toolkit";
import { permissionsInitialStateTypes } from "./permissions.types";
import { REQUEST_STATUS } from "src/constants/request-status";
import { getPermissions } from "./permissions.api";

export const permissionsInitialState: permissionsInitialStateTypes = {
  permissions: [],
  permissionsError: false,
  permissionsRequestStatus: REQUEST_STATUS?.IDEL,
};

const permissions = createSlice({
  name: "permissions",
  initialState: permissionsInitialState,
  reducers: {
    resetPermissions(state) {
      state.permissions = permissionsInitialState?.permissions;
    },
    setErrorPermissions(state, action) {
      state.permissionsError = action.payload;
    },
  },

  extraReducers: (builder) => {
    // START GET PROFILE COMPANY PANEL
    builder.addCase(getPermissions.pending, (state, action) => {
      state.permissionsRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(getPermissions.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.permissions = data?.allowedPermissions?.map(
        (obj: any) => obj?.label
      );
      state.permissionsError = false;
      state.permissionsRequestStatus = REQUEST_STATUS.SUCCESS;
    });
    builder.addCase(getPermissions.rejected, (state, error) => {
      state.permissionsError = true;
      state.permissionsRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END GET PROFILE COMPANY PANEL
  },
});

export const permissionsActions = permissions.actions;
export const permissionsReducer = permissions.reducer;
