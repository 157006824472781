import { Typography, Grid, Box } from "@mui/material";
import { TextFieldWrapper } from "src/ui/FormsInput/TextFieldWrapper";
import SelectWrapper from "src/ui/FormsInput/SelectWrapper";
import { Country } from "src/mock-data/CountryDetail/CountryDetail";
import { useAppSelector } from "src/hooks";
import { useEffect, useMemo } from "react";

const CompanyDetails = ({ setShowButton }: any) => {

  const onClickHandler = () => {
    // setShowButton(true)
  }

  return (
    <>
      <Typography variant="h1"> Company Details</Typography>
      <Grid container spacing={2} mt={2} rowGap={2}>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            label={
              <label>
                Company Name (Legal Entry)
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.companyName"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            label={
              <label>
                Company Name (Short Name to log-in with)
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.shortName"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Company Number
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.companyNumber"
            type="number"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Company Organization Type
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.organizationType"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Country
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.country"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            label={
              <label>
                Official Company Email
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.email"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Company Phone
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.phone"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Company Mobile
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.mobile"
            type="text"
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetails;
