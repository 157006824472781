import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
  authActions,
  getPermissions,
  // loginInWithToken,
  setAxiosInterceptors,
  handleAuthentication,
  isAuthenticated,
  permissionsActions,
} from "src/store";
import SplashScreen from "./SplashScreen";
import AccessDenied from "src/ui/AccessDenied/AccessDenied";

function Auth({ children }: any) {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const { permissionsError } = useAppSelector((store) => store.permissions);
  const handleClose = () =>
    dispatch(permissionsActions.setErrorPermissions(false));

  useEffect(() => {
    const initAuth = async () => {
      setAxiosInterceptors({
        onLogout: () => dispatch(authActions.logout()),
      });
      handleAuthentication();
      if (isAuthenticated()) {
        await dispatch(getPermissions());
        // await dispatch(loginInWithToken());
      }
      setLoading(false);
    };
    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  if (permissionsError) {
    return <AccessDenied open={permissionsError} handleClose={handleClose} />;
  }

  return children;
}

export default Auth;
