import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetRequest, apiPatchRequest, apiPostRequest } from "src/helpers";
import { endpoints } from "src/config";

export const profileDataCompanyGet = createAsyncThunk(
  "company/getProfileData",
  async () => {
    return await apiGetRequest(endpoints.companyProfile).then((res) => {
      if(res.data.defaultRole !== "COMPANY_ADMIN"){ // API response structure is different for COMPANY_ADMIN and different for rest of company users i.e EVIDENCE_CHECKER
        let updatedRes = {
          ...res,
          data: {
            ...res.data,
            postCode: res.data.postCodeUser,
            surName: res.data.surname,
            // foreName: res.data.foreName,
            // addressLine1: res.data.addressLine1,
            // addressLine2: res.data.addressLine2,
            // townOrCity: res.data.townOrCity,
          }
        }
        return Promise.resolve(updatedRes)  
      }
      return Promise.resolve(res)
    }
    );
  }
)



export const profileDataCompanyPost = createAsyncThunk(
  "company/postProfileData",
  async (payload: any) => {
    return await apiPatchRequest(endpoints.companyUpdate, payload).then((res) =>
      Promise.resolve(res)
    );
  }
);

export const profileDataIndividualGet = createAsyncThunk(
  "individual/getProfileData",
  async () => {
    return await apiGetRequest(endpoints.individualProfile).then((res) =>
      Promise.resolve(res)
    );
  }
);

export const profileDataIndividualPost = createAsyncThunk(
  "individual/postProfileData",
  async (payload: any) => {
    return await apiPatchRequest(endpoints.individualUpdate, payload).then(
      (res) => Promise.resolve(res)
    );
  }
);

export const profilePicGet = createAsyncThunk("getProfilePic", async () => {
  return await apiGetRequest(endpoints.getProfilePic).then((res) =>
    Promise.resolve(res)
  );
});

export const profilePicPost = createAsyncThunk(
  "postProfilePic",
  async (payload: any, { rejectWithValue, fulfillWithValue }) => {
    const res = await apiPostRequest(endpoints.postProfilePic, payload);
    return Promise.resolve(res);
  }
);

export const profilePicPatch = createAsyncThunk(
  "patchProfilePic",
  async (payload: any) => {
    const res = await apiPatchRequest(endpoints.patchProfilePic, payload);
    return Promise.resolve(res);
  }

);
