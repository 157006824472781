import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./HeaderProfile.scss";
import profileIcon from "src/assets/images/layout/header/profile.svg";
import changePassIcon from "src/assets/images/layout/header/changepassword.svg";
import { UserData } from "src/mock-data/layout/LayoutData";
import IndividualEditProfile from "src/components/ViewProfile/IndividualEditProfile/IndividualViewProfile";
import { NavLink } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
import CompanyEditProfile from "src/components/ViewProfile/CompanyEditProfile/CompanyViewProfile";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { profilePicGet } from "src/store";

function HeaderProfile() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userRole } = useAppSelector((store) => store.auth);
  const { profileImage } = useAppSelector((store) => store.profile);
  const dispatch = useAppDispatch();
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getProfilePic = useCallback(async () => {
    try {
      await dispatch(profilePicGet());
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    getProfilePic();
  }, [getProfilePic]);

  return (
    <React.Fragment>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "false" : undefined}
        onClick={handleClick}
        sx={{ p: 0, minWidth: 0 }}
        className="test-button"
      >
        <Avatar
          src={profileImage ?? UserData.avatar}
          alt="profile image"
          sx={{
            width: userRole === "SYSTEM_ADMIN" ? "26px" : "32px",
            height: userRole === "SYSTEM_ADMIN" ? "26px" : "32px",
          }}
        />
      </Button>
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            className="profile-avatar"
            onClick={() => {
              setOpenViewModal(true);
              setAnchorEl(null);
            }}
          >
            <Box sx={{ display: "flex", alignItem: "center" }}>
              <img src={profileIcon} alt="" />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  pl: 4,
                  color: "secondary.main",
                }}
              >
                View Profile
              </Box>
            </Box>
          </MenuItem>
          <MenuItem>
            <NavLink to={"/change-password"} onClick={() => setAnchorEl(null)}>
              <Box sx={{ display: "flex", alignItem: "center" }}>
                <img src={changePassIcon} alt="icon" />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    pl: 4,
                    color: "secondary.main",
                  }}
                >
                  Change Password
                </Box>
              </Box>
            </NavLink>
          </MenuItem>
        </Menu>
      )}
      {userRole === "INDIVIDUAL_USER" ? (
        <IndividualEditProfile
          openViewModal={openViewModal}
          setOpenViewModal={setOpenViewModal}
        />
      ) : (
        userRole && (
          <CompanyEditProfile
            openViewModal={openViewModal}
            setOpenViewModal={setOpenViewModal}
          />
        )
      )}
    </React.Fragment>
  );
}

export default HeaderProfile;
