import * as Yup from "yup";

const postCodeRegex = /^[a-zA-Z0-9 ]*$/;

export const companyDetails = Yup.object({
  companyDetails: Yup.object().shape({
    companyName: Yup.string()
      .required("Please Enter Your Company Name (Legal Entery)")
      .trim(),
    shortName: Yup.string()
      .required("Please Enter Your Company Short Name")
      .trim(),
    companyNumber: Yup.string()
      .required("Please Enter Your Company Number")
      .trim(),
    organizationType: Yup.string()
      .required("Please Enter your Organization Type")
      .trim(),
    country: Yup.string().required("Please Enter your country").trim(),
    email: Yup.string()
      .email("Email Is Invalid")
      .required("Please Enter Your |Official Email")
      .trim(),
    phone: Yup.string()
      .required("Please Enter A Valid Company Phone Number")
      .trim(),
    mobile: Yup.string()
      .required("Please Enter A Valid Company Mobile Number")
      .trim(),
  }),
});

export const yourDetails = Yup.object({
  yourDetails: Yup.object().shape({
    foreName: Yup.string().required("Please Enter Forename").trim(),
    surName: Yup.string().required("Please Enter Surname").trim(),
    gender: Yup.string().required("Please Select gender").trim(),
  }),
});

export const addressDetails = Yup.object({
  addressDetails: Yup.object().shape({
    addressLine1: Yup.string()
      .required("Please Enter Your Address Line 1")
      .trim(),
    townOrCity: Yup.string().required("Please Enter Your Town").trim(),
    postCode: Yup.string()
      .required("Please Enter Your Postcode")
      .matches(postCodeRegex, "Please Enter Valid Post Code")
      .trim(),
  }),
});

export const validationSchemaAll = Yup.object({
  companyDetails: Yup.object().shape({
    companyName: Yup.string()
      .required("Please Enter Your Company Name (Legal Entry)")
      .trim(),
    shortName: Yup.string()
      .required("Please Enter Your Company Short Name")
      .trim(),
    companyNumber: Yup.string()
      .required("Please Enter Your Company Number")
      .trim(),
    organizationType: Yup.string()
      .required("Please Enter your Organization Type")
      .trim(),
    country: Yup.string().required("Please Enter your country").trim(),
    email: Yup.string()
      .email("Email Is Invalid")
      .required("Please Enter Your |Official Email")
      .trim(),
    phone: Yup.string().required("Please Enter Phone Number").trim(),
    mobile: Yup.string().required("Please Enter Mobile Number").trim(),
    addressLine1: Yup.string()
      .required("Please Enter Your Address Line 1")
      .trim(),
    townOrCity: Yup.string().required("Please Enter Your Town").trim(),
    postCode: Yup.string()
      .required("Please Enter Your Postcode")
      .matches(postCodeRegex, "Please Enter Valid Post Code")
      .trim(),
  }),
  yourDetails: Yup.object().shape({
    foreName: Yup.string()
      .max(80, "Forename should not exceed more than 80 characters")
      .required("Please Enter Forename")
      .trim(),
    surName: Yup.string()
      .max(80, "Forename should not exceed more than 80 characters")
      .required("Please Enter Surname")
      .trim(),
    gender: Yup.string().required("Please Select gender").trim(),
  }),
});
