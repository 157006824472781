import { Box, Typography, Grid } from "@mui/material";
import moment from "moment";
import { GenderData } from "src/mock-data/ViewProfile/ViewProfile";
import { DateofBirth } from "src/ui/coustomDate/coustomDate";
import SelectWrapper from "src/ui/FormsInput/SelectWrapper";
import { TextFieldWrapper } from "src/ui/FormsInput/TextFieldWrapper";

const PersonalInfo = ({ setShowButton }: any) => {
  return (
    <>
      <Typography variant="h1">Personal Info</Typography>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Forename
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="foreName"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Surname
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="surName"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Date of Birth
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="DOB"
            maxDate={moment(DateofBirth).format("YYYY-MM-DD")}
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            disabled
            label={
              <label>
                Email
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="email"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                User Name
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="userName"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectWrapper
            onClick={() => setShowButton(true)}
            label="Gender"
            options={GenderData}
            name="gender"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Phone
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="phone"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Mobile
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="mobile"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Nationality
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="nationality"
            type="text"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInfo;
