import { Box, Grid, Typography } from "@mui/material";
import { TextFieldWrapper } from "src/ui/FormsInput/TextFieldWrapper";
import { useAppSelector } from "src/hooks";
import { useEffect, useMemo } from "react";

const Address = ({ setShowButton }: any) => {
  const { userRole } = useAppSelector((store) => store.auth);
  const isDisabled = useMemo(() => {
    return userRole === "COMPANY_ADMIN" ? false : true
  }, [userRole])

  const onClickHandler = () => {
    // setShowButton(true)
  }

  return (
    <>
      <Typography variant="h1">Address</Typography>
      <Grid container spacing={2} mt={2} rowGap={2}>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Address Line 1
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.addressLine1"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Address Line 2
              </label>
            }
            name="companyDetails.addressLine2"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Town / City
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.townOrCity"
            type="text"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={onClickHandler}
            label={
              <label>
                Postcode
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="companyDetails.postCode"
            type="text"
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Address;
