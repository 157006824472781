import React from "react";
import { Box } from "@mui/system";
import bellIcon from "src/assets/images/layout/header/bell.svg";
import logoutIcon from "src/assets/images/layout/header/logout.svg";
import avatar from "src/assets/AdminHeader/Avatar.png";
import searchIcon from "src/assets/images/layout/header/search.svg";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "src/hooks";
import { useNavigate } from "react-router";
import { authActions } from "src/store";
import HeaderProfile from "../Header/header-content/headerProfile/HeaderProfile";
import HeaderNotifications from "../Header/header-content/headerProfile/HeaderNotifications";

const AdminHeader = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(authActions.logout());
      navigate("/login");
    } catch {
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        paddingLeft: "1%",
        marginLeft: "1%",
        paddingBottom: "20px",
      }}
    >
      <Box
        sx={{
          py: 1,
          pl: 3,
          pr: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "0px 0px 0px 20px",
          boxShadow: "0px 4px 16px rgba(131, 164, 249, 0.25)",
        }}
        className="admin-header-main"
      >
        <Box>
          <Box
            className="search-item "
            sx={{
              display: { sm: "none", lg: "flex" },
              position: "relative",
              "& input": {
                background: "#E5E7EB",
                border: "none",
                outline: "none",
                height: "34px",
                pl: "30px",
              },
              "& img": {
                position: "absolute",
                top: "11px",
                left: "11px",
              },
            }}
          >
            <input
              className="border-radius-4"
              type="search"
              name=""
              id=""
              placeholder="Search"
            />
            <img src={searchIcon} alt="" />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ px: 2, cursor: "pointer" }}>
            <HeaderNotifications icon={bellIcon} />
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              className="profile-section"
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <HeaderProfile />
            </Box>
          </Box>
          <Box sx={{ px: 2, pt: 1, cursor: "pointer" }} onClick={handleLogout}>
            <img src={logoutIcon} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminHeader;
