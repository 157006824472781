import { Typography, Grid, Box } from "@mui/material";
import { TextFieldWrapper } from "src/ui/FormsInput/TextFieldWrapper";
import { Country } from "src/mock-data/CountryDetail/CountryDetail";
import AutoCompleteSelect from "src/ui/AutoCompleteSelect/AutoCompleteSelect";
import useCities from "src/hooks/useCities";
import { useAppSelector } from "src/hooks";

const AddressDetails = ({ setShowButton }: any) => {
  const { profileData } = useAppSelector((store) => store.profile);
  const { cities, setSearchTerm } = useCities(profileData?.country);

  const onChangeHandler = (country: any) => {
    setSearchTerm(country);
    setShowButton(true);
  };
  return (
    <>
      <Typography variant="h1"> Address Details</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Address First Line
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="addressFirstLine"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={<label>Address Second Line</label>}
            name="addressSecondLine"
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteSelect
            label="Country"
            options={Country}
            onChange={onChangeHandler}
            name="country"
            state={profileData?.country || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteSelect
            onChange={() => setShowButton(true)}
            label="Town / City"
            options={cities}
            name="townCity"
            state={profileData?.townCity || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldWrapper
            onClick={() => setShowButton(true)}
            label={
              <label>
                Post Code
                <Box
                  sx={{
                    color: "error.main",
                    display: "inline-block",
                    pl: 0.5,
                  }}
                >
                  *
                </Box>
              </label>
            }
            name="postCode"
            type="text"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressDetails;
