import { LoadingButton } from "@mui/lab";
import { CustomButtonProps } from "./custom-button.types";

export const CustomButton = ({
  children,
  onClick,
  color,
  hoverColor,
  borderColor,
  hoverBorderColor,
  background,
  hoverBackground = "",
  padding,
  width,
  height = "42px",
  StartIcon,
  EndIcon,
  margin,
  fullWidth = true,
  disabled,
  variant = "contained",
  maxWidth,
  minWidth = "120px",
  borderRadius,
  loading = false,
  type,
  border,
  transition,
  boxShadow = 0,
  hoverBoxShadow = 0,
  dependiences,
  className = "",
}: CustomButtonProps): JSX.Element => {
  return (
    <LoadingButton
      className={className}
      type={type}
      loading={loading}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={StartIcon}
      endIcon={EndIcon}
      sx={{
        boxShadow,
        transition,
        border,
        borderRadius,
        borderColor,
        padding,
        margin,
        width,
        color,
        minWidth,
        maxWidth,
        whiteSpace: "nowrap",
        height,
        backgroundColor: background,
        ...dependiences,
        "&:hover": {
          boxShadow: hoverBoxShadow,
          backgroundColor: hoverBackground,
          borderColor: hoverBorderColor,
          color: hoverColor,
        },
      }}
    >
      {children}
    </LoadingButton>
  );
};
