import React, { createContext, useState, useEffect } from "react";
import _ from "lodash";
import { THEMES } from "src/constants/theme-constant";
import { storeSettings } from "src/utils/settings";

type settings = {
  direction: string;
  responsiveFontSizes: boolean;
  theme: string;
};

type SettingsContextType = {
  settings: settings;
  saveSettings: any;
};

const defaultSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: THEMES.ONE_DARK,
};

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: SettingsContextType = {
  settings: defaultSettings,
  saveSettings: {},
};

const SettingsContext = createContext<SettingsContextType>(defaultBehaviour);

export function SettingsProvider({ settings, children }: any) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
