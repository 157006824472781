import { useEffect, useState, FC, Dispatch, SetStateAction } from "react";
import { Form, Formik } from "formik";
import { Box } from "@mui/material";
import { CustomTabs } from "src/ui/custom-tabs";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
// import OtherInfo from "./OtherInfo/OtherInfo";
import AddressDetails from "./AddressDetails/AddressDetails";
import {
  validateAddressDetails,
  // validateOtherInfo,
  validatePersonalInfo,
} from "./ValidationSchema";
import { CustomButton } from "src/ui/custom-button";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { REQUEST_STATUS } from "src/constants/request-status";
import { profileDataIndividualPost } from "src/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";

interface closebtn {
  setOpen: Dispatch<SetStateAction<boolean>>;
  showButton: any;
  setShowButton: any;
}

const EditForm: FC<closebtn> = ({ setOpen, showButton, setShowButton }) => {
  const [value] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { postProfileDataRequestStatus, profileData } = useAppSelector(
    (store) => store.profile
  );
  let isPostProfileData =
    postProfileDataRequestStatus === REQUEST_STATUS?.LOADING;

  const [validationSchema, setValidationSchema] =
    useState<any>(validatePersonalInfo);

  useEffect(() => {
    switch (value) {
      case 1:
        setValidationSchema(validateAddressDetails);
        break;
      case 2:
        setValidationSchema(validatePersonalInfo);
        break;
    }
  }, [value]);

  const onSubmit = async (data: any) => {
    const payload = data;
    try {
      const response = await dispatch(profileDataIndividualPost(payload));
      const { data, status } = unwrapResult(response);
      if (status === 200) {
        enqueueSnackbar(data?.message, {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, {
        variant: "error",
      });
    } finally {
      setShowButton(false);
    }
  };
  const customTabsData = [
    {
      label: "Personal Information",
      content: <PersonalInfo setShowButton={setShowButton} />,
    },
    {
      label: "Address Details",
      content: <AddressDetails setShowButton={setShowButton} />,
    },
    // {
    //   label: "Other Info",
    //   content: <OtherInfo setShowButton={setShowButton} />,
    // },
  ];

  return (
    <Formik
      initialValues={{
        foreName: profileData?.foreName || "",
        surName: profileData?.surName || "",
        DOB: profileData?.DOB || "",
        email: profileData?.email || "",
        userName: profileData?.userName || "",
        gender: profileData?.gender || "",
        phone: profileData?.phone || "",
        mobile: profileData?.mobile || "",
        nationality: profileData?.nationality || "",
        addressFirstLine: profileData?.addressFirstLine || "",
        addressSecondLine: profileData?.addressSecondLine || "",
        country: profileData?.country || "",
        townCity: profileData?.townCity || "",
        postCode: profileData?.postCode || "",
        // userType: profileData?.userType || "",
        // rightToWork: profileData?.rightToWork || "",
      }}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <Box
            height={"65vh"}
            padding={"30px 50px"}
            sx={{
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            position={"relative"}
          >
            <CustomTabs
              mapperObjProp={customTabsData}
              alignItemsTab="left"
              justifyContentTab="left"
              paddingPanel="20px 0px"
              tabsWidth={"100%"}
              tabsShadow={2}
              tabsBorderRadius={1}
              tabsContainerPadding={1}
            />

            {showButton && (
              <Box
                position={{ xs: "unset", md: "absolute" }}
                bottom={"15%"}
                right={"0"}
                pr={"40px"}
              >
                <CustomButton
                  onClick={() => {
                    setOpen(false);
                    setShowButton(false);
                  }}
                  type="button"
                  className={"transitionButton"}
                  width="140px"
                  height="45px"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  color="common.white"
                  background="secondary.main"
                  hoverBackground="secondary.main"
                  width="140px"
                  height="45px"
                  margin="0 10px"
                  loading={isPostProfileData}
                >
                  Save
                </CustomButton>
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditForm;
