/**
 * @file Contains the auth slice of the app store state.
 * Here the slice is initialized.
 */
import { createSlice } from "@reduxjs/toolkit";
import { AuthSliceState } from "./auth.types";
import { loginIndividual, loginCompany, loginInWithToken } from "./auth.api";
import { REQUEST_STATUS } from "src/constants/request-status";

export const authInitialState: AuthSliceState = {
  loggedIn: false,
  message: null,
  user: {
    userId: "",
  },
  authToken: null,
  refreshToken: null,
  userRole: null,
  loginRequestStatus: REQUEST_STATUS.IDEL,
  tokenLoginError: false,
  rememberMeState: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    logout(state: any) {
      // state.user = authInitialState.user;
      // state.loggedIn = false;
      // state.authToken = "";
      // state.refreshToken = "";
      // state.message = null;
      // state.userRole = "";
      // state.tokenLoginError = false;
      // localStorage.clear();
    },
    setUser(state: any, action: any) {
      state.user = action.payload;
    },
    setErrorDialog(state: any, action: any) {
      state.tokenLoginError = action.payload;
    },
    clickedRememberMe(state, action) {
      sessionStorage.setItem("rememberMe", action.payload);
      state.rememberMeState = Boolean(action.payload);
    },
  },

  extraReducers: (builder) => {
    // START LOGIN INDIVIDUAL PANEL
    builder.addCase(loginIndividual.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.loginRequestStatus = REQUEST_STATUS.SUCCESS;
      state.authToken = data?.data?.authToken;
      state.refreshToken = data?.data?.refreshToken;
      state.user.userId = data?.data?.userId;
      state.userRole = data?.data?.roles[0];
      state.loggedIn = true;
      localStorage.setItem("accessToken", data?.data?.authToken);
      localStorage.setItem("refreshToken", data?.data?.refreshToken);
    });
    builder.addCase(loginIndividual.pending, (state, action) => {
      state.loginRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(loginIndividual.rejected, (state, error) => {
      state.loggedIn = false;
      state.loginRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END LOGIN INDIVIDUAL PANEL

    // START LOGIN COMPANY PANEL
    builder.addCase(loginCompany.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.loginRequestStatus = REQUEST_STATUS.SUCCESS;
      state.authToken = data?.data?.authToken;
      state.refreshToken = data?.data?.refreshToken;
      state.user.userId = data?.data?.userId;
      state.userRole = data?.data?.roles[0];
      state.loggedIn = true;
      localStorage.setItem("accessToken", data?.data?.authToken);
      localStorage.setItem("refreshToken", data?.data?.refreshToken);
    });
    builder.addCase(loginCompany.pending, (state, action) => {
      state.loginRequestStatus = REQUEST_STATUS.LOADING;
    });
    builder.addCase(loginCompany.rejected, (state, error) => {
      state.loggedIn = false;
      state.loginRequestStatus = REQUEST_STATUS.FAILURE;
    });
    // END LOGIN COMPANY PANEL

    // START LOGIN WITH TOKEN ADMIN PANEL
    builder.addCase(loginInWithToken.fulfilled, (state, action: any) => {
      const { data } = action?.payload;
      state.tokenLoginError = false;
      state.user = data;
    });
    builder.addCase(loginInWithToken.pending, (state, action) => {});
    builder.addCase(loginInWithToken.rejected, (state, action) => {
      const { message } = action?.error;
      state.message = message || "Something Went Wrong";
      state.tokenLoginError = true;
    });
    // END LOGIN WITH TOKEN ADMIN PANEL
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
