import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  authReducer,
  rejectedDbsAppReducer,
  companyAdminReducer,
  dbsAppReducer,
  profileReducer,
  permissionsReducer,
} from "./slices";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useQueryClient } from "@tanstack/react-query";
const persistConfig = {
  key: "root",
  version: 1,
  blacklist: ["profile", "permissions", "dbsApp"],
  storage,
};

const appReducer = combineReducers({
  auth: authReducer,
  companyAdmin: companyAdminReducer,
  rejectedDbsApplication: rejectedDbsAppReducer,
  dbsApp: dbsAppReducer,
  profile: profileReducer,
  permissions: permissionsReducer,
});


const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === "auth/logout") {
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

// Remove in future acording to requirments
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const createStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreState: true,
        ignoreActions: true,
        // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof createStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof createStore.dispatch;
