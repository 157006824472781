import Grid from "@mui/material/Grid";
import "./HeaderContent.scss";
import logoutIcon from "src/assets/images/layout/header/logout.svg";
import searchIcon from "src/assets/images/layout/header/search.svg";
import plusIcon from "src/assets/images/layout/header/plus.svg";
import searchBlanceIcon from "src/assets/images/layout/header/searchbal.svg";
import poundIcon from "src/assets/images/layout/header/pound.svg";
import { UserData } from "src/mock-data/layout/LayoutData";
import HeaderProfile from "./headerProfile/HeaderProfile";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { AuthSliceState, authActions } from "src/store";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import {
  MUIFONTWEIGHT600,
  MUITERTIARYTITLE,
} from "src/constants/mui-styles-constant";
import SearchIcon from "@mui/icons-material/Search";
import HeaderNotifications from "./headerProfile/HeaderNotifications";
import { useQueryClient } from "@tanstack/react-query";

const HeaderContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { profileData } = useAppSelector(
    (store: { profile: any }) => store.profile
  );
  const { userRole } = useAppSelector(
    (store: { auth: AuthSliceState }) => store.auth
  );

  const queryClient = useQueryClient()

  const handleLogout = async () => {
    try {
      dispatch(authActions.logout());
      navigate("/login");
      queryClient.invalidateQueries()
    } catch {
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <Box className="header-inner">
      <Box className="header-content">
        <Grid container spacing={2}>
          <Grid item xs={6} md={4} order={{ xs: -1, md: 0 }}>
            <Box className="user-name">
              <div className="font-size-14 white font-weight-500">
                {profileData?.foreName} {profileData?.surName}
              </div>
              <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                <div className="company-inner font-size-12 secondary-color font-weight-600 ">
                  {userRole}
                </div>
                {profileData?.companyName && (
                  <div className="company-inner font-size-12 secondary-color font-weight-600 ">
                    {profileData?.companyName}
                  </div>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              className="search-item "
              sx={{ display: { xs: "flex", md: "none", lg: "flex" } }}
            >
              <input
                className="border-radius-4"
                type="search"
                name=""
                id=""
                placeholder="Search"
              />
              <img src={searchIcon} alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} md={4} order={{ xs: -1, md: 0 }}>
            <Box
              className="user-profile"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ mt: 1, display: { xs: "none", md: "block", lg: "none" } }}>
                <div className="search-box">
                  <SearchIcon className="search-btn" />

                  <input
                    className="search-txt"
                    type="text"
                    name=""
                    placeholder="Search"
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },
                  alignItems: "center",
                  color: "common.white",
                  padding: "0 5px",
                }}
              >
                |
              </Box>
              <HeaderNotifications />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "common.white",
                  padding: "0 15px",
                }}
              >
                |
              </Box>
              <Box
                className="profile-section"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <HeaderProfile />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "common.white",
                  padding: "0 15px",
                }}
              >
                |
              </Box>
              <Box
                className="profile-section"
                onClick={handleLogout}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img src={logoutIcon} alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={"amount"}
        sx={{
          fontSize: MUITERTIARYTITLE,
          fontWeight: MUIFONTWEIGHT600,
          display: "flex",
          color: "common.white",
          justifyContent: { lg: "flex-end", xs: "center" },
        }}
      >
        <Box
          className="balance amountInner"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img src={searchBlanceIcon} alt="" />
          <Typography
            variant="subtitle2"
            className="label"
            sx={{
              pl: 1,
              color: "common.white",
              fontWeight: MUIFONTWEIGHT600,
            }}
          >
            MY BALANCE
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          className="amountInner"
        >
          <img src={plusIcon} alt="" />
          <Typography
            variant="subtitle2"
            className="label"
            sx={{
              pl: 1,
              color: "common.white",
              fontWeight: MUIFONTWEIGHT600,
            }}
          >
            PAYMENT
          </Typography>
        </Box>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="subtitle2"
          className="label"
          sx={{
            color: "common.white",
            fontWeight: MUIFONTWEIGHT600,
            mt: 2,
          }}
        >
          DBS FEES Balance
        </Typography>
        <Box
          sx={{
            color: "primary.main",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <img src={poundIcon} alt="" />
          <Typography variant="h1">
            {UserData.balance === 0 ? "0.00" : UserData.balance}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderContent;
