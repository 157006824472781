import { useCallback, useEffect, useState } from "react";
import { apiGetRequest } from "src/helpers";
import { endpoints } from "src/config/endpoints";
import { useSnackbar } from "notistack";

const useCities = (country = null) => {
  const [cities, setCities] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState(country);
  const { enqueueSnackbar } = useSnackbar();

  const getCities = useCallback(() => {
    apiGetRequest(`${endpoints.getCountry}/?Search=${searchTerm}`)
      .then(({ data }) => {
        setCities([...data, { _id: "123", city_name: "Other" }]);
      })
      .catch((error) => {
        enqueueSnackbar(error?.message || "Something went wrong", {
          variant: "error",
        });
      });
  }, [enqueueSnackbar, searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      getCities();
    }
  }, [getCities, searchTerm, country]);

  return {
    cities,
    setSearchTerm,
  };
};

export default useCities;
