import { createSlice } from "@reduxjs/toolkit";
import { IInitialStateTypesRejectedDbsApplication } from "./rejected-dbs.types";

const initialState: IInitialStateTypesRejectedDbsApplication = {
  markAsReadModalOpener: false,
  viewResultModalOpener: false,
  dbsApplicationHistoryModalOpener: false,
};

const rejectedDbsApplicationSlice = createSlice({
  name: "rejectedDbsApplication",
  initialState,
  reducers: {
    setMarkAsReadModalOpener(state, action) {
      state.markAsReadModalOpener = action.payload;
    },
    setViewResultModalOpener(state, action) {
      state.viewResultModalOpener = action.payload;
    },
    setDbsApplicationHistoryModalOpener(state, action) {
      state.dbsApplicationHistoryModalOpener = action.payload;
    },
  },
});

export const rejectedDbsAppActions = rejectedDbsApplicationSlice.actions;
export const rejectedDbsAppReducer = rejectedDbsApplicationSlice.reducer;
