import dashboardIcon from "src/assets/images/layout/left-sidebar/home.svg";
import applyForIcon from "src/assets/images/layout/left-sidebar/apply-for.svg";
import dbsApplicationIcon from "src/assets/images/layout/left-sidebar/dbs-application.svg";
import accountsIcon from "src/assets/images/layout/left-sidebar/account.svg";
import trainingIcon from "src/assets/images/layout/left-sidebar/training.svg";
import helpIcon from "src/assets/images/layout/left-sidebar/help.svg";
import userIcon from "src/assets/images/layout/left-sidebar/userManual.svg";
import reportIcon from "src/assets/images/layout/left-sidebar/report.svg";
import settingsIcon from "src/assets/images/layout/left-sidebar/settings.svg";
import manageUsersIcon from "src/assets/images/layout/left-sidebar/manage-users.svg";
import apiInventoryIcon from "src/assets/images/layout/left-sidebar/api-inventory.svg";
import systemPerformanceIcon from "src/assets/images/layout/left-sidebar/system-performance.svg";
import backUpIcon from "src/assets/images/layout/left-sidebar/back-up.svg";
import manageNotificationIcon from "src/assets/images/layout/left-sidebar/manage-notification.svg";
import auditLogsIcon from "src/assets/images/layout/left-sidebar/audit-logs.svg";
import ratingFeedbackIcon from "src/assets/images/layout/left-sidebar/ratings-feedback.svg";
import commentsIcon from "src/assets/CounterSignatory/comment1.svg";

interface dropDownItem {
  id: number;
  navName: string;
  link: string;
  name?: JSX.Element;
  permission: string;
}
export interface ISIDEBARITEM {
  id: number;
  navName: string;
  name?: JSX.Element;
  icon?: string;
  link?: string;
  dropDownItem?: dropDownItem[];
  linkRef?: any;
  permission?: string | string[];
}

export const SIDEBAR_DATA: ISIDEBARITEM[] = [
  {
    id: 1,
    link: "/dashboard",
    navName: "Dashboard",
    icon: dashboardIcon,
    permission: "dashboard",
  },
  {
    id: 2,
    link: "/apply-for",
    navName: "Apply For",
    linkRef: "apply-for",
    icon: applyForIcon,
    permission: "apply_for",
  },
  {
    id: 3,
    navName: "DBS Applications",
    icon: dbsApplicationIcon,
    linkRef: "dbs-applications",
    dropDownItem: [
      {
        id: 3,
        navName: "Applications Pending for EC",
        link: "/dbs-applications/application-pending-for-ec-applicant",
        permission: "application_pending_for_ec_applicant",
      },
      {
        id: 3,
        navName: "View Assigned DBS Application",
        link: "/dbs-applications/view-assigned-dbs-application",
        permission: "application_view_assigned_dbs_application",
      },
      {
        id: 3,
        navName: "Pay or Fix issues with DBS Applications",
        link: "/dbs-applications/pay-or-fix-issues",
        permission: "application_pay_or_fix_issues",
      },
      {
        id: 3,
        navName: "Fix issues with DBS Applications",
        link: "/dbs-applications/pay-or-fix-issues",
        permission: "application_fix_issues",
      },
      {
        id: 3,
        navName: "Incomplete DBS Applications",
        link: "/dbs-applications/incomplete-dbs-appication",
        permission: "application_incomplete",
      },
      {
        id: 3,
        navName: "Rejected DBS Applications",
        link: "/dbs-applications/rejected-dbs-application",
        permission: "application_rejected",
      },
      {
        id: 3,
        navName: "Application Pending For DBS",
        link: "/dbs-applications/application-pending-for-dbs",
        permission: "application_pending_for_dbs",
      },
      {
        id: 3,
        navName: "DBS Application Requiring Payment",
        link: "/dbs-applications/dbs-application-requiring-payment",
        permission: "application_requiring_payment",
      },
      {
        id: 3,
        navName: "DBS Application Already Paid For",
        link: "/dbs-applications/dbs-application-already-paid-for",
        permission: "application_already_paid",
      },
      {
        id: 3,
        navName: "Completed DBS Checks",
        link: "/dbs-applications/completed-dbs-appication",
        permission: "application_completed_dbs_checks_certificate_posted_out",
      },
      {
        id: 3,
        navName: "Application Pending For Evidence Check",
        link: "/dbs-applications/application-pending-evidence-check",
        permission: "application_pending_evidence_check",
      },
      {
        id: 3,
        navName: "DBS Certificate Eligible For Reprint",
        link: "/dbs-applications/dbs-certificate-eligible",
        permission: "application_certificates_eligible_reprint",
      },
      {
        id: 3,
        navName: "Spend History For Your DBS Checks",
        link: "/dbs-applications/spend-history-for-dbs-checks",
        // permission: "application_spent_history_check",
        permission: "none",
      },
      {
        id: 3,
        navName: "Successful Payments To Your DBS Account",
        link: "/dbs-applications/successful-payment-to-your-dbs-account",
        // permission: "application_successful_payments_dbs_account",
        permission: "none",
      },
    ],
  },
  {
    id: 4,
    navName: "Setting",
    icon: settingsIcon,
    linkRef: "setting",
    dropDownItem: [
      {
        id: 4,
        navName: "Evidence Checkers",
        link: "/setting/evidence-checkers",
        permission: "settings_evidence_checker",
      },
      {
        id: 4,
        navName: "Manage Email Templates",
        link: "/setting/manage-email-templates",
        permission: "settings_manage_email_template",
      },
      {
        id: 4,
        navName: "Edit Company",
        link: "/setting/edit-company",
        permission: "settings_edit_company_details",
      },
      {
        id: 4,
        navName: "Manage Users",
        link: "/setting/manage-users",
        permission: "settings_manage_users",
      },
      {
        id: 4,
        navName: "Dashboards",
        link: "/setting/dashboards",
        permission: "settings_manage_dashboard",
      },
    ],
  },
  {
    id: 5,
    navName: "My Accounts",
    icon: accountsIcon,
    linkRef: "my-accounts",
    dropDownItem: [
      {
        id: 5,
        navName: "Invoices",
        link: "/my-accounts/invoices",
        permission: "accounts_invoices",
      },
      {
        id: 5,
        navName: "Adjustment",
        link: "/my-accounts/adjustment",
        permission: "accounts_adjustment",
      },
      {
        id: 5,
        navName: "Transactions",
        link: "/my-accounts/transactions",
        permission: "accounts_transactions",
      },
      {
        id: 5,
        navName: "Payments",
        link: "/my-accounts/payments",
        permission: "accounts_payments",
      },
      {
        id: 5,
        navName: "Refunds",
        link: "/my-accounts/refunds",
        permission: "accounts_refunds",
      },
      {
        id: 5,
        navName: "Spent",
        link: "/my-accounts/spent",
        permission: "accounts_spent",
      },
      {
        id: 5,
        navName: "User Spent",
        link: "/my-accounts/user-spent",
        permission: "accounts_user_spent",
      },
      {
        id: 5,
        navName: "Balance Details",
        link: "/my-accounts/balance-details",
        permission: "accounts_balance_details",
      },
    ],
  },
  {
    id: 6,
    navName: "Trainings",
    icon: trainingIcon,
    link: "/training",
    permission: "training",
  },
  {
    id: 7,
    navName: "Manage",
    icon: manageUsersIcon,
    link: "/manage-users",
    dropDownItem: [
      {
        id: 3,
        navName: "Manage Users",
        link: "/dbs-applications/view-assigned-dbs-application",
        permission: "none",
      },
      {
        id: 3,
        navName: "Roles and Rights",
        link: "/dbs-applications/pay-or-fix-issues",
        permission: "none",
      },
    ],
  },
  {
    id: 8,
    navName: "API Inventory",
    icon: apiInventoryIcon,
    link: "/api-inventory",
    permission: "API_inventory",
  },
  {
    id: 9,
    navName: "System Performance",
    icon: systemPerformanceIcon,
    link: "/system-performance",
    permission: "system_performance",
  },
  {
    id: 10,
    navName: "Back Up",
    icon: backUpIcon,
    link: "/back-up",
    permission: "none",
  },
  {
    id: 11,
    navName: "Manage Notification",
    icon: manageNotificationIcon,
    link: "/manage-notification",
    permission: "manage_notification",
  },
  {
    id: 12,
    navName: "Audit Logs",
    icon: auditLogsIcon,
    link: "/audit-logs",
    permission: "audit_log",
  },
  {
    id: 13,
    navName: "Ratings & Feedback",
    icon: ratingFeedbackIcon,
    link: "/rating-and-feedback",
    permission: "none",
  },
  {
    id: 14,
    navName: "Report", // None at the moment for all roles
    link: "/report",
    icon: reportIcon,
    permission: "none",
  },
  {
    id: 15,
    link: "/counter-signatory/reviewed-applications",
    navName: "View Assigned Applications",
    linkRef: "counter-signatory",
    icon: dbsApplicationIcon,
    permission: "dashboard_total_assigned_dbs_application",
  },
  {
    id: 16,
    link: "/post-comment-application",
    navName: "Post Comment on Application",
    icon: commentsIcon,
    permission: "dashboard_post_comment_application",
  },
  {
    id: 17,
    navName: "Help",
    link: "/help",
    icon: helpIcon,
    permission: "default",
  },
  {
    id: 18,
    link: "/user-manual",
    navName: "User Manual",
    icon: userIcon,
    permission: "default",
  },
  // {
  //   id: 19,
  //   link: "/user-manual",
  //   navName: "Help IT Desk",
  //   icon: userIcon,
  //   permission: "help_IT_desk"
  // },
];
