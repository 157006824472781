import { Dispatch, FC, useRef, SetStateAction } from "react";
import DBS from "src/assets/ViewProfile/DBS.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import profileIcon from "src/assets/ViewProfile/profile-icon.svg";
import { GRADIANTBLUE } from "src/constants/mui-styles-constant";
import { convertToBase64 } from "src/utils/convertToBase64";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { REQUEST_STATUS } from "src/constants/request-status";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import { profilePicPatch, profilePicPost } from "src/store";
import { unwrapResult } from "@reduxjs/toolkit";

interface HeaderData {
  setOpen: Dispatch<SetStateAction<boolean>>;
  profileName: string;
  userType: string;
}

const Header: FC<HeaderData> = ({ setOpen, profileName, userType }) => {
  const fileRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { postProfilePicRequestStatus, profileImage } = useAppSelector(
    (store) => store.profile
  );

  let isPostProfilePic =
    postProfilePicRequestStatus === REQUEST_STATUS?.LOADING;

  const handleImage = async (event: any) => {
    try {
      const base64 = await convertToBase64(event.target.files[0]);
      const payload = {
        profilePic: base64,
      };
      let response = null;
      if (Boolean(profileImage)) {
        response = await dispatch(profilePicPatch(payload));
      } else {
        response = await dispatch(profilePicPost(payload));
      }
      const { data, status } = unwrapResult(response);
      if (status === 200 || status === 201) {
        enqueueSnackbar(data?.message, {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, {
        variant: "error",
      });
    }
  };

  return (
    <Grid
      sx={{
        background: GRADIANTBLUE,
        borderRadius: "8px 8px 0 0",
        height: {xs:"230",sm:"207px"},
        boxShadow: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: "12px 20px",
        }}
      >
        <CancelIcon
          onClick={() => {
            setOpen(false);
          }}
          sx={{ cursor: "pointer", color: "common.white" }}
        />
      </Box>
      <Box width={"200px"} margin={"auto"}>
        <img src={DBS} alt="DBS Background" />
      </Box>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          ml: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            alt="Profile Picture"
            src={profileImage}
            sx={{ width: "101px", height: "101px" }}
          />

          <Box
            position={"absolute"}
            left={"75px"}
            top={"70px"}
            sx={{ cursor: "pointer" }}
          >
            {isPostProfilePic ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress sx={{ color: "secondary.main" }} />
              </Box>
            ) : (
              <>
                <img
                  src={profileIcon}
                  onClick={() => {
                    fileRef.current.click();
                  }}
                  alt="cameraIcon"
                />
                <input
                accept="image/*"
                  hidden
                  type="file"
                  ref={fileRef}
                  onChange={handleImage}
                />
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            pl: "10px",
          }}
        >
          <Typography variant="h1" sx={{ color: "common.white" }}>
            {profileName}
          </Typography>
          <Typography
            variant="h2"
            sx={{ color: "common.white", fontWeight: 400 }}
          >
            {userType}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Header;
