import { useAppSelector } from "src/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function GuestGuard({ children }: any) {
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector((store) => store.auth);
  // const isToken = localStorage.getItem("accessToken");
  // If Token is Already in Store then It should REDIRECT TO Dashbard
  useEffect(() => {
    if (loggedIn) {
      return navigate("/dashboard");
    }
  }, [loggedIn, navigate]);
  // If Token is Already in Store then It should REDIRECT TO Dashbard

  return children;
}

export default GuestGuard;
