import { useEffect, useState, FC, Dispatch, SetStateAction } from "react";
import { Form, Formik } from "formik";
import { Box } from "@mui/material";
import { CustomTabs } from "src/ui/custom-tabs";
import {
  companyDetails,
  addressDetails,
  yourDetails,
  validationSchemaAll,
} from "./ValidationSchema";
import { CustomButton } from "src/ui/custom-button";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import YourDetails from "./YourDetails/YourDetails";
import Address from "./Address/Address";
import { REQUEST_STATUS } from "src/constants/request-status";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { profileDataCompanyGet, profileDataCompanyPost } from "src/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";

interface closebtn {
  setOpen: Dispatch<SetStateAction<boolean>>;
  showButton: any;
  setShowButton: any;
}

const EditForm: FC<closebtn> = ({ setOpen, setShowButton, showButton }) => {
  const [validationSchema, setValidationSchema] = useState<any>(companyDetails);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { userRole } = useAppSelector((store) => store.auth);
  const value = useAppSelector((state) => state.dbsApp.tabValue);

  const { postProfileDataRequestStatus, profileData } = useAppSelector(
    (store: { profile: any }) => store.profile
  );
  let isPostProfileData =
    postProfileDataRequestStatus === REQUEST_STATUS?.LOADING;

  useEffect(() => {
    switch (value) {
      case 1:
        setValidationSchema(yourDetails);
        break;
      case 2:
        setValidationSchema(addressDetails);
        break;
      default:
        setValidationSchema(companyDetails);
    }
    if (value === 0 || value === 2) {
      // Hide save buttons for Company Details and Address tab
      setShowButton(false);
    }
  }, [setShowButton, value]);

  const onSubmit = async ({
    companyDetails,
    companyAdminUser,
    yourDetails,
  }: any) => {
    let payload = {};
    if (userRole !== "COMPANY_ADMIN") {
      payload = {
        yourDetails: {
          foreName: yourDetails.foreName,
          surName: yourDetails.surName,
          gender: yourDetails.gender,
          // addressLine1: companyDetails.addressLine1,
        },
      };
    } else {
      payload = {
        companyDetails,
        companyAdminUser,
        yourDetails,
      };
    }
    try {
      const response = await dispatch(profileDataCompanyPost(payload));
      const { data, status } = unwrapResult(response);
      if (status === 200) {
        enqueueSnackbar(data?.message, {
          variant: "success",
        });
        dispatch(profileDataCompanyGet());
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, {
        variant: "error",
      });
    } finally {
      setShowButton(false);
      setOpen(false);
    }
  };

  const customTabsData = [
    {
      label: "Company Details",
      content: <CompanyDetails setShowButton={setShowButton} />,
    },
    {
      label: "Your Details",
      content: <YourDetails setShowButton={setShowButton} />,
    },
    {
      label: "Address",
      content: <Address setShowButton={setShowButton} />,
    },
  ];

  return (
    <Formik
      initialValues={{
        companyDetails: {
          companyName: profileData?.companyName || "",
          shortName: profileData?.shortName || "",
          companyNumber: profileData?.companyNumber || "",
          organizationType: profileData?.organizationType || "",
          email: profileData?.email || "",
          phone: profileData?.phone || "",
          mobile: profileData?.mobile || "",
          country: profileData?.country || "",
          townOrCity: profileData?.townOrCity || "",
          postCode: profileData?.postCodeAddress || "",
          addressLine1: profileData?.addressLine1 || "",
          addressLine2: profileData?.addressLine2 || "",
        },
        yourDetails: {
          foreName: profileData?.foreName || "",
          surName: profileData?.surName || "",
          gender: profileData?.gender || "",
        },
        companyAdminUser: {
          foreName: profileData?.foreNameAdmin || "",
          surName: profileData?.surNameAdmin || "",
          email: profileData?.emailAdmin || "",
          mobile: profileData?.mobileAdmin || "",
        },
      }}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={
        userRole === "COMPANY_ADMIN" ? validationSchemaAll : yourDetails
      }
    >
      {() => (
        <Form>
          <Box
            height={"65vh"}
            padding={"30px 50px"}
            sx={{
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            position={"relative"}
          >
            <CustomTabs
              mapperObjProp={customTabsData}
              alignItemsTab="left"
              justifyContentTab="left"
              paddingPanel="20px 0px"
              tabsWidth={"100%"}
              tabsShadow={2}
              tabsBorderRadius={1}
              tabsContainerPadding={1}
            />

            {showButton && (
              <Box
                position={{ xs: "unset", md: "absolute" }}
                bottom={"10%"}
                right={"0"}
                pr={"40px"}
                textAlign={"end"}
              >
                <CustomButton
                  onClick={() => {
                    setOpen(false);
                    setShowButton(false);
                  }}
                  type="button"
                  className={"transitionButton"}
                  width="140px"
                  height="45px"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  color="common.white"
                  background="secondary.main"
                  hoverBackground="secondary.main"
                  width="140px"
                  height="45px"
                  margin="0 10px"
                  loading={isPostProfileData}
                >
                  Save
                </CustomButton>
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditForm;
