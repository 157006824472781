export const flattenObj = (obj: any) => {
  const flatObj: any = {};

  for (let key of Object.keys(obj)) {
    if (obj[key] && typeof obj[key] === "object")
      Object.assign(flatObj, flattenObj(obj[key]));
    else flatObj[key] = obj[key];
  }
  return flatObj;
};
