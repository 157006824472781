import * as Yup from "yup";

export const NumberRegex = /^\+44\d{10}$/;

export const validatePersonalInfo = Yup.object({
  foreName: Yup.string().max(80, "Forename should not exceed more than 80 characters").required("Please Enter Your Forename").trim(),
  surName: Yup.string().max(80, "Forename should not exceed more than 80 characters").required("Please Enter Your Surname").trim(),
  DOB: Yup.string().required("Please Enter Your Birth Day").trim(),  
  userName: Yup.string().required("Please Enter a Username").trim(),
  gender: Yup.string().required("Please Select a Gender").trim(),
  phone: Yup.string().required("Please Enter a Valid Phone Number").matches(NumberRegex, "Please Enter Valid Phone Number").trim(),
  mobile: Yup.string().required("Please Enter a Valid Mobile Number").matches(NumberRegex, "Please Enter Valid Mobile Number").trim(),
  nationality: Yup.string().required("Please Enter Your Nationality").trim(),
});
export const validateAddressDetails = Yup.object({
  addressFirstLine: Yup.string().required("Please Enter Address Line 1").trim(),
  addressSecondLine: Yup.string().required("Please Enter Address Line 2").trim(),
  country: Yup.string().required("Please Enter Your Country").trim(),
  city: Yup.string().required("Please Enter Your City").trim(),
  postCode: Yup.string().required("Please Enter Your Postcode").trim(),
});
// export const validateOtherInfo = Yup.object({
//   userType: Yup.string().required("Please Enter Your User Type"),
//   rightToWork: Yup.string().required("Please Enter Your Work"),
// });
