export const endpoints = {
  indivisualSignUp: `auth/signup/individual`,
  companySignUp: `auth/signup/company`,
  indivisualSignIn: `auth/signin/individual`,
  individualPasswordReset: "/auth/forgotPasswordIndividual",
  companyPasswordReset: "/auth/forgotPasswordCompany",
  companySignIn: `auth/signin/company`,
  silentLogin: `auth/me`,
  changeTempPasswordIndividual: `auth/change_temp_password_Individual`,
  changeTempPasswordCompany: `auth/change_temp_password_company`,
  changeIndividualPassword: `auth/change_password_Individual`,
  changeCompanyPassword: `auth/change_password_company`,
  resendCode: `auth/resendCode`,
  createDbsApplication: `/application/createByCompanyAdmin`,
  createUser: `admin/createUser`,
  getUserListApi: "userpermissions/getSubUsers",
  individualProfile: `individual/get`,
  getUserApplicationData: `application/getDetails`,
  individualUpdate: `individual/update`,
  getUserPermissions: `userpermissions/get`,
  companyProfile: `company/get`,
  companyUpdate: `company/update`,
  updateDbsApplicationData: `application/update`,
  createDraftDbsApp: "application/createByCompanyAdmin",
  createIndividualDraftDbsApp: "application/createByIndividual",
  getUserDBSApplications: "application/getApplicationsOfSubusers",
  getApplicationsOfAdminByStatus: "application/getApplicationsOfAdminByStatus",
  listAll: "application/listAll",
  getProfilePic: "profilePic/get",
  postProfilePic: "profilePic/create",
  patchProfilePic: "profilePic/update",
  applicationAlreadyPaidFor: "/application/applicationAlreadyPaidFor",
  certificatePostedOutErasedApp:
    "application/applicationCertificatePostedOutErasedApplications",
  certificatePostedOutDbsResult: "application/applicationCertificatePostedOut",
  applicationResult: "/application/result",
  getAssignedApplications: "/application/getCompanyAssignedApplications",
  getApplicationHistory: "/application/getApplicationHistory",
  getApplicationtotalCount: "application/totalCount",
  getReconcilation: "/application/getReconcilation", //Pass id
  incompleteDbsApps: "application/getInCompleteApplications",
  appsPendingForEvidenceChecker:
    "application/applicationsPendingForEvedenceChecker",
  certificatePostedConfirmReceipt: "application/confirmReceipt",
  setPassword: "auth/confrimforgotpassword",
  listofEC: "application/getAssignedToMeApplications",
  sentToCS: "application/submitByEvedenceChecker/",
  userPermissionHistory: "/userpermissions/userHistory",

  //START SETTINGS => Manage Users
  getManageUsers: "userpermissions/getApplicantsOfCompany",
  activeDeactiveApplicant: "userpermissions/activateUser/",
  getEvidenceCheckersOfCompany: "userpermissions/getEvedenceCheckersOfCompany",
  getOnlyEvidenceCheckersOfCompany:
    "userpermissions/getOnlyEvedenceCheckersOfCompany",
  assignApptoEvidenceChecker: "userpermissions/assignApplicant",
  //END SETTINGS => Manage Users
  eligibleForReprint: "application/certificateEligibleForReprint",
  comment: "application/comment",
  MarkApplicationAsRead: "/application/markApplicationAsRead",
  rejectApplication: "/application/reject",
  getReconcilationDetail: "/application/getReconcilation",
  getSingleUser: "/userpermissions/getSingleUser",
  //START COUNTER SIGNATORY
  companyAppOfCounterSignatory: "/application/companyAppOfCounterSignatory",
  applicationsAssignedToCounterSignatory:
    "/application/getAllApplicationsAssignedToCounterSignatory",
  submitApplicationsByCounterSignatory:
    "/application/submitApplicationsByCounterSignatory", //Pass applicatoin ID in url
  //END COUNTER SIGNATORY
  getCompanyInfo: "/company/get",
  resentToCounterSignatory: "/application/comment",
  getTotalCountCs: "/application/totalCountCSApp",
  applicationsAssignedToEC:
    "/application/getAssignedToEvedenceCheckerApplications",
  reAssignApplication: "/application/reAssign",
  getCountry: "company/SearchCites",
};
