import profileIcon from "src/assets/images/layout/header/vector.svg";
import facebook from "src/assets/images/layout/footer/facebook.svg";
import instagram from "src/assets/images/layout/footer/insta.svg";
import linkedIn from "src/assets/images/layout/footer/linkedIn.svg";
import youtube from "src/assets/images/layout/footer/youtube.svg";
interface USERDATA {
  name: string;
  userType: string;
  balance: number;
  avatar: string;
}
export const UserData: USERDATA = {
  name: "Faisal Naeem",
  userType: "Individual User",
  balance: 0.0,
  avatar: profileIcon,
};

// ----------Footer-----------

interface FOOTERDATA {
  id: number;
  icon: string;
}
interface TERMANDCONDTION {
  id: number;
  name: string;
  separator?: string;
}
export const FooterData: FOOTERDATA[] = [
  { id: 1, icon: facebook },
  { id: 2, icon: youtube },
  { id: 3, icon: instagram },
  { id: 4, icon: linkedIn },
];
export const FooterTermAndCondtion: TERMANDCONDTION[] = [
  { id: 1, name: "Terms & Conditions", separator: "|" },
  { id: 2, name: "Privacy Policy", separator: "|" },
  { id: 3, name: "Support" },
];
