import { SnackbarProvider } from "notistack";
import Auth from "src/components/Auth";
import { ThemeProvider } from "@mui/material/styles";
import CustomRoutes from "src/routes";
import { BrowserRouter } from "react-router-dom";
import useSettings from "src/hooks/useSettings";
import { createCustomTheme } from "src/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();
  const { settings } = useSettings();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={createCustomTheme(settings)}>
        <SnackbarProvider maxSnack={1}>
          <Auth>
            <BrowserRouter>
              <CustomRoutes />
            </BrowserRouter>
          </Auth>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
