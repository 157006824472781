import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import bellIcon from "src/assets/images/layout/header/bell.svg";
import timer from "src/assets/Header/timer.svg";
import {
    MUIFONTWEIGHT500,
    MUIFONTWEIGHT600,
    MUITERTIARYTITLE,
    MUITITLE10,
} from "src/constants/mui-styles-constant";
import React, { useState } from "react";

const HeaderNotifications = ({
    icon = bellIcon
}: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            className="profile-section"
            sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 1 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <img src={icon} alt="" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                className="account-menu font-family-poppins"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 42,
                            height: 32,
                            ml: -0.5,
                            mr: -1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 15,
                            width: 15,
                            height: 15,
                            bgcolor: "background.default",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Box
                    className="main-notification-container"
                    sx={{
                        height: "200px",
                        width: "100%",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { display: "none" },
                    }}
                >
                    {[1, 2, 3, 4, 4].map((item, i) => (
                        <MenuItem key={i} sx={{ cursor: "unset" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        textAlign: "right",
                                    }}
                                >
                                    <img src={timer} alt="watch" />
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: "text.secondary",
                                            paddingLeft: "3px",
                                            display: "flex",
                                            fontSize: MUITITLE10,
                                        }}
                                    >
                                        3hours ago
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: "common.black",
                                            pt: 1,
                                            pb: 0.9,
                                        }}
                                    >
                                        New User Created
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: "text.secondary",
                                            fontSize: MUITITLE10,
                                        }}
                                    >
                                        Ani Cristea created a new user
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                    ))}
                </Box>
                <MenuItem
                    sx={{
                        borderTop: "1px solid #f1f1f1",
                        marginTop: "8px",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            p: "5px",
                            fontSize: MUITERTIARYTITLE,
                            fontWeight: MUIFONTWEIGHT500,
                            color: "primary.main",
                        }}
                    >
                        Read All Notifications
                    </Box>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default HeaderNotifications
