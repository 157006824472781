import React from "react";
import "./Header.scss";
import bookIcon from "src/assets/images/layout/header/book.svg";
import tickIcon from "src/assets/images/layout/header/tick.svg";
import HeaderContent from "./header-content/HeaderContent";
import { Box } from "@mui/material";

const Header: React.FC = () => {
  return (
    <Box className="header-layout font-family-poppins">
      <img className="book-icon" src={bookIcon} alt="" />
      <img className="tick-icon" src={tickIcon} alt="" />
      <HeaderContent />
    </Box>
  );
};

export default Header;
