import React, { useState, useEffect } from "react";
import "./LeftSidebar.scss";
import dbsIcon from "src/assets/images/layout/left-sidebar/dbsIcon.svg";
import drawerIcon from "src/assets/images/layout/left-sidebar/drawer.svg";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  SIDEBAR_DATA,
  ISIDEBARITEM,
} from "src/mock-data/RoutesData/RoutesData";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { MUITITLE10 } from "src/constants/mui-styles-constant";
import { useAppSelector } from "src/hooks";

const LeftSidebar = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [subNav, setSubNav] = React.useState<ISIDEBARITEM | null>(null);
  const [dynamicSidebar, setDynamicSidebar] = useState<any>([]);
  const [showSupport, setShowSupport] = useState<Boolean>(false);

  const { permissions } = useAppSelector((store) => store.permissions);

  // close side navbar on screen size
  const [width] = useWindowSize();
  const mobileNavbar =
    width < 600 && props.sidebarToggler
      ? "siderbar-mobile-view position-absolute"
      : "";
  const backDrop =
    width < 600 && props.sidebarToggler ? "side-navbar-backdrop" : "";
  function useWindowSize() {
    const [size, setSize] = useState([0]);
    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  //---------------------------------------//
  useEffect(() => {
    props.setSidebarToggler(width > 600);
  }, [width]);
  // ---------------

  function handleSupportVisibility() {
    let supportElementExist = dynamicSidebar?.find(
      (item: any) => item.id === 8 || item.id === 9
    ); // if an item/module exists that should come under the SUPPORT Heading
    setShowSupport(supportElementExist ? true : false);
  }

  // 1. Initially, filter the submodules based on the permissions coming from backend
  // 2. Then filter the main module: the main module must be visible if at least one submodule permission is coming from backend else hide main module
  const applyPermissionsOnRoutes = () => {
    let permissionsOnSubmodule = SIDEBAR_DATA?.map((module) => {
      return {
        ...module,
        dropDownItem: module?.dropDownItem?.filter((submod) =>
          permissions?.includes(submod?.permission)
        ),
      };
    });
    let permissionsOnModule = permissionsOnSubmodule?.filter((obj) => {
      if (obj.dropDownItem) {
        // This check is only required for module containing submodules
        return obj.dropDownItem.length > 0;
      }
      return permissions.some(
        (permission: any) =>
          permission.includes(obj.permission) || obj.permission === "default"
      );
    });
    setDynamicSidebar(permissionsOnModule);
  };

  useEffect(() => {
    if (dynamicSidebar.length > 0) {
      handleSupportVisibility();
    }
  }, [dynamicSidebar]);

  useEffect(() => {
    applyPermissionsOnRoutes();
  }, [permissions]);

  // nav Link Handler
  const navLinkHandler = (item: any) => {
    item.link && navigate(item.link);

    if (subNav?.id === item.id) {
      // props.setprops.activeItem(!props.activeItem);
      props.setActiveItemList(!props.activeItem);
    } else if (item?.dropDownItem) {
      // props.setprops.activeItem(true);
      props.setActiveItemList(true);
      setSubNav(item);
    } else {
      // props.setprops.activeItem(false);
      props.setActiveItemList(false);
    }
  };
  //-------------

  // inner Navbar Drop Down
  const dropdownHandler = (val: ISIDEBARITEM) => {
    val?.link ? navigate(val?.link) : navigate("");
    !props.sidebarToggler
      ? props.setActiveItemList(false)
      : props.setActiveItemList(props.activeItem);
  };
  //------------

  return (
    <Box className="sidebar" sx={{ position: "relative" }}>
      {/* backdrop */}
      <Box
        className={backDrop}
        onClick={() => props.setSidebarToggler(false)}
      ></Box>
      {/* end of backdrop */}
      <Box
        className={`${
          props.sidebarToggler ? "sidebar-300" : "sidebar-50"
        } ${mobileNavbar}`}
      >
        <Box className="siderbar-inner">
          <Box
            className="siderbar-header"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              className={
                props.sidebarToggler ? "dbs-Icon text-center" : "d-none"
              }
            >
              <img src={dbsIcon} alt="" />
            </Box>
            <button
              className="drawer-button display-flex cursor-pointer"
              onClick={props.sidebarTogglerHandler}
            >
              <img src={drawerIcon} alt="" />
            </button>
          </Box>
          <Box className="links-area customScrollbar">
            {dynamicSidebar?.map((item: any, index: number) => (
              <Box key={item.id}>
                <Box
                  className="navlinks text-decoration display-flex"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navLinkHandler(item)}
                >
                  {(String(location.pathname) === item.link ||
                    String(location.pathname).includes(item?.linkRef)) && (
                    <div className="selected"></div>
                  )}
                  {String(location.pathname) !== item.link && (
                    <div className="not-selected"></div>
                  )}
                  <Box
                    className={`navlinks-list border-radius-8 ${
                      String(location.pathname) === item.link
                        ? "navlink-selected"
                        : ""
                    }`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        className="icon"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img src={item.icon} alt="" />
                      </Box>
                      <Box
                        className={` ${
                          props.sidebarToggler ? "text " : "d-none"
                        }`}
                      >
                        {item.navName}
                      </Box>
                    </Box>
                    <Box
                      className="drop-down-icon"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {item.dropDownItem && props.sidebarToggler && (
                        <ExpandMoreIcon
                          sx={{ color: "#9D9E9E" }}
                          className={
                            props.activeItem && subNav?.id === item.id
                              ? "dropdown-icon"
                              : "dropdown-icon-initial"
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                {/* drop down Items array */}
                {item.dropDownItem && (
                  <Box
                    className={`dropdown-item ${
                      subNav?.id === item.id &&
                      props.activeItem &&
                      props.sidebarToggler
                        ? "tansition-class-open"
                        : ""
                    }`}
                  >
                    {props.sidebarToggler && (
                      <ul className={"dropdown-list font-weight-600"}>
                        {item.dropDownItem.map((dropdown: any, index: any) => (
                          <li
                            key={index}
                            onClick={() => dropdownHandler(dropdown)}
                            className={
                              String(location.pathname) === dropdown.link
                                ? "navlink-selected"
                                : ""
                            }
                          >
                            <Box>{dropdown.navName}</Box>
                          </li>
                        ))}
                      </ul>
                    )}
                    {!props.sidebarToggler && (
                      <ul
                        className={
                          "dropdown-list-onClose position-absolute font-weight-600"
                        }
                      >
                        {subNav?.id === item.id &&
                          props.activeItem &&
                          item.dropDownItem.map((dropdown: any, index: any) => (
                            <li
                              key={index}
                              onClick={() => dropdownHandler(dropdown)}
                              className={
                                String(location.pathname) === dropdown.link
                                  ? "navlink-selected"
                                  : ""
                              }
                            >
                              <Box>{dropdown.navName}</Box>
                            </li>
                          ))}
                      </ul>
                    )}
                  </Box>
                )}
                {index === dynamicSidebar.length - 3 && (
                  <Box
                    className="support-label"
                    sx={{ color: "text.secondary", fontSize: MUITITLE10 }}
                  >
                    SUPPORT
                  </Box>
                )}
                {/* ------------------ */}
                {/* {showSupport && // Make sure the elements after SUPPORT heading exists
                ((item?.id !== 8 && item.id !== 9)) && // Validity of following logic requires current item not to be the support item/module itself
                (dynamicSidebar[index + 1]?.id === 8 || dynamicSidebar[index + 1]?.id === 9) && // Show support text just before the support modules
                (
                  <Box
                    className="support-label"
                    sx={{ color: "text.secondary", fontSize: MUITITLE10 }}
                  >
                    SUPPORT
                  </Box>
                )
              } */}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSidebar;
