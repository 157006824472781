import { useAppSelector } from "src/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SplashScreen from "./SplashScreen";

function AuthGuard({ children }: any) {
  const [isLoading, setLoading] = useState(true);
  const { loggedIn } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const isToken = localStorage.getItem("accessToken");

  // If Token is not inValid in Store then It should REDIRECT TO LOGIN
  useEffect(() => {
    if (!loggedIn) {
      return navigate("/login");
    }
    setLoading(false);
  }, [isToken, loggedIn, navigate]);
  // If Token is not inValid then It should REDIRECT TO LOGIN

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default AuthGuard;
