export const Country = [
  {
    id: 1,
    checked: false,
    Value: "Afghanistan",
    text: "Afghanistan",
  },
  // {
  //   id: 2,
  //   checked: false,
  //   Value: "Aland Islands",
  //   text: "Aland Islands",
  // },
  {
    id: 3,
    checked: false,
    Value: "Albania",
    text: "Albania",
  },
  {
    id: 4,
    checked: false,
    Value: "Algeria",
    text: "Algeria",
  },
  // {
  //   id: 5,
  //   checked: false,
  //   Value: "American Samoa",
  //   text: "American Samoa",
  // },
  {
    id: 6,
    checked: false,
    Value: "Andorra",
    text: "Andorra",
  },
  {
    id: 7,
    checked: false,
    Value: "Angola",
    text: "Angola",
  },
  // {
  //   id: 8,
  //   checked: false,
  //   Value: "Anguilla",
  //   text: "Anguilla",
  // },
  // {
  //   id: 9,
  //   checked: false,
  //   Value: "Antarctica",
  //   text: "Antarctica",
  // },
  {
    id: 10,
    checked: false,
    Value: "Antigua And Barbuda",
    text: "Antigua And Barbuda",
  },
  {
    id: 11,
    checked: false,
    Value: "Argentina",
    text: "Argentina",
  },
  {
    id: 12,
    checked: false,
    Value: "Armenia",
    text: "Armenia",
  },
  // {
  //   id: 13,
  //   checked: false,
  //   Value: "Aruba",
  //   text: "Aruba",
  // },
  {
    id: 14,
    Value: "Australia",
    text: "Australia",
  },
  {
    id: 15,
    Value: "Austria",
    text: "Austria",
  },
  {
    id: 16,
    Value: "Azerbaijan",
    text: "Azerbaijan",
  },
  {
    id: 17,
    Value: "The Bahamas",
    text: "The Bahamas",
  },
  {
    id: 18,
    Value: "Bahrain",
    text: "Bahrain",
  },
  {
    id: 19,
    Value: "Bangladesh",
    text: "Bangladesh",
  },
  {
    id: 20,
    Value: "Barbados",
    text: "Barbados",
  },
  {
    id: 21,
    Value: "Belarus",
    text: "Belarus",
  },
  {
    id: 22,
    Value: "Belgium",
    text: "Belgium",
  },
  {
    id: 23,
    Value: "Belize",
    text: "Belize",
  },
  {
    id: 24,
    Value: "Benin",
    text: "Benin",
  },
  // {
  //   id: 25,
  //   Value: "Bermuda",
  //   text: "Bermuda",
  // },
  {
    id: 26,
    Value: "Bhutan",
    text: "Bhutan",
  },
  {
    id: 27,
    Value: "Bolivia",
    text: "Bolivia",
  },
  {
    id: 28,
    Value: "Bosnia and Herzegovina",
    text: "Bosnia and Herzegovina",
  },
  {
    id: 29,
    Value: "Botswana",
    text: "Botswana",
  },
  // {
  //   id: 30,
  //   Value: "Bouvet Island",
  //   text: "Bouvet Island",
  // },
  // {
  //   id: 31,
  //   Value: "Brazil",
  //   text: "Brazil",
  // },
  // {
  //   id: 32,
  //   Value: "British Indian Ocean Territory",
  //   text: "British Indian Ocean Territory",
  // },
  {
    id: 33,
    Value: "Brunei",
    text: "Brunei",
  },
  {
    id: 34,
    Value: "Bulgaria",
    text: "Bulgaria",
  },
  {
    id: 35,
    Value: "Burkina Faso",
    text: "Burkina Faso",
  },
  {
    id: 36,
    Value: "Burundi",
    text: "Burundi",
  },
  {
    id: 37,
    Value: "Cambodia",
    text: "Cambodia",
  },
  {
    id: 38,
    Value: "Cameroon",
    text: "Cameroon",
  },
  {
    id: 39,
    Value: "Canada",
    text: "Canada",
  },
  {
    id: 40,
    Value: "Cape Verde",
    text: "Cape Verde",
  },
  // {
  //   id: 41,
  //   Value: "Cayman Islands",
  //   text: "Cayman Islands",
  // },
  {
    id: 42,
    Value: "Central African Republic",
    text: "Central African Republic",
  },
  {
    id: 43,
    Value: "Chad",
    text: "Chad",
  },
  {
    id: 44,
    Value: "Chile",
    text: "Chile",
  },
  {
    id: 45,
    Value: "China",
    text: "China",
  },
  {
    id: 46,
    Value: "Christmas Island",
    text: "Christmas Island",
  },
  // {
  //   id: 47,
  //   Value: "Cocos (Keeling) Islands",
  //   text: "Cocos (Keeling) Islands",
  // },
  {
    id: 48,
    Value: "Colombia",
    text: "Colombia",
  },
  {
    id: 49,
    Value: "Comoros",
    text: "Comoros",
  },
  {
    id: 50,
    Value: "Congo",
    text: "Congo",
  },
  {
    id: 51,
    Value: "Democratic Republic of the Congo",
    text: "Democratic Republic of the Congo",
  },
  // {
  //   id: 52,
  //   Value: "Cook Islands",
  //   text: "Cook Islands",
  // },
  {
    id: 53,
    Value: "Costa Rica",
    text: "Costa Rica",
  },
  // {
  //   id: 54,
  //   Value: "Cote D'Ivoire (Ivory Coast)",
  //   text: "Cote D'Ivoire (Ivory Coast)",
  // },
  {
    id: 55,
    Value: "Croatia",
    text: "Croatia",
  },
  {
    id: 56,
    Value: "Cuba",
    text: "Cuba",
  },
  {
    id: 57,
    Value: "Cyprus",
    text: "Cyprus",
  },
  // {
  //   id: 58,
  //   Value: "AnguiCzech Republiclla",
  //   text: "Czech Republic",
  // },
  {
    id: 59,
    Value: "Denmark",
    text: "Denmark",
  },
  {
    id: 60,
    Value: "Djibouti",
    text: "Djibouti",
  },
  {
    id: 61,
    Value: "Dominica",
    text: "Dominica",
  },
  {
    id: 62,
    Value: "Dominican Republic",
    text: "Dominican Republic",
  },
  {
    id: 63,
    Value: "East Timor",
    text: "East Timor",
  },
  {
    id: 64,
    Value: "Ecuador",
    text: "Ecuador",
  },
  {
    id: 65,
    Value: "Egypt",
    text: "Egypt",
  },
  {
    id: 66,
    Value: "El Salvador",
    text: "El Salvador",
  },
  {
    id: 67,
    Value: "Equatorial Guinea",
    text: "Equatorial Guinea",
  },
  {
    id: 68,
    Value: "Eritrea",
    text: "Eritrea",
  },
  {
    id: 69,
    Value: "Estonia",
    text: "Estonia",
  },
  {
    id: 70,
    Value: "Ethiopia",
    text: "Ethiopia",
  },
  // {
  //   id: 71,
  //   Value: "Falkland Islands",
  //   text: "Falkland Islands",
  // },
  // {
  //   id: 72,
  //   Value: "Faroe Islands",
  //   text: "Faroe Islands",
  // },
  {
    id: 73,
    Value: "Fiji Islands",
    text: "Fiji Islands",
  },
  {
    id: 74,
    Value: "Finland",
    text: "Finland",
  },
  {
    id: 75,
    Value: "France",
    text: "France",
  },
  // {
  //   id: 76,
  //   Value: "French Guiana",
  //   text: "French Guiana",
  // },
  // {
  //   id: 77,
  //   Value: "French Polynesia",
  //   text: "French Polynesia",
  // },
  // {
  //   id: 78,
  //   Value: "French Southern Territories",
  //   text: "French Southern Territories",
  // },
  {
    id: 79,
    Value: "Gabon",
    text: "Gabon",
  },
  {
    id: 80,
    Value: "Gambia The",
    text: "Gambia The",
  },
  {
    id: 81,
    Value: "Georgia",
    text: "Georgia",
  },
  {
    id: 82,
    Value: "Germany",
    text: "Germany",
  },
  {
    id: 83,
    Value: "Ghana",
    text: "Ghana",
  },
  // {
  //   id: 84,
  //   Value: "Gibraltar",
  //   text: "Gibraltar",
  // },
  {
    id: 85,
    Value: "Greece",
    text: "Greece",
  },
  // {
  //   id: 86,
  //   Value: "Greenland",
  //   text: "Greenland",
  // },
  {
    id: 87,
    Value: "Grenada",
    text: "Grenada",
  },
  // {
  //   id: 88,
  //   Value: "Guadeloupe",
  //   text: "Guadeloupe",
  // },
  // {
  //   id: 89,
  //   Value: "Guam",
  //   text: "Guam",
  // },
  {
    id: 90,
    Value: "Guatemala",
    text: "Guatemala",
  },
  // {
  //   id: 91,
  //   Value: "Guernsey and Alderney",
  //   text: "Guernsey and Alderney",
  // },
  {
    id: 92,
    Value: "Guinea",
    text: "Guinea",
  },
  {
    id: 93,
    Value: "Guinea-Bissau",
    text: "Guinea-Bissau",
  },
  {
    id: 94,
    Value: "Guyana",
    text: "Guyana",
  },
  {
    id: 95,
    Value: "Haiti",
    text: "Haiti",
  },
  // {
  //   id: 96,
  //   Value: "Heard Island and McDonald Islands",
  //   text: "Heard Island and McDonald Islands",
  // },
  {
    id: 97,
    Value: "Honduras",
    text: "Honduras",
  },
  // {
  //   id: 98,
  //   Value: "Hong Kong S.A.R.",
  //   text: "Hong Kong S.A.R.",
  // },
  {
    id: 99,
    Value: "Hungary",
    text: "Hungary",
  },
  {
    id: 100,
    Value: "Iceland",
    text: "Iceland",
  },
  {
    id: 101,
    Value: "India",
    text: "India",
  },
  {
    id: 102,
    Value: "Indonesia",
    text: "Indonesia",
  },
  {
    id: 103,
    Value: "Iran",
    text: "Iran",
  },
  {
    id: 104,
    Value: "Iraq",
    text: "Iraq",
  },
  {
    id: 105,
    Value: "Ireland",
    text: "Ireland",
  },
  {
    id: 106,
    Value: "Israel",
    text: "Israel",
  },
  {
    id: 107,
    Value: "Italy",
    text: "Italy",
  },
  {
    id: 108,
    Value: "Jamaica",
    text: "Jamaica",
  },
  {
    id: 109,
    Value: "Japan",
    text: "Japan",
  },
  // {
  //   id: 110,
  //   Value: "Jersey",
  //   text: "Jersey",
  // },
  {
    id: 111,
    Value: "Jordan",
    text: "Jordan",
  },
  {
    id: 112,
    Value: "Kazakhstan",
    text: "Kazakhstan",
  },
  {
    id: 113,
    Value: "Kenya",
    text: "Kenya",
  },
  {
    id: 114,
    Value: "Kiribati",
    text: "Kiribati",
  },
  // {
  //   id: 115,
  //   Value: "Paola",
  //   text: "Paola",
  // },
  {
    id: 116,
    Value: "South Korea",
    text: "South Korea",
  },
  {
    id: 117,
    Value: "Kuwait",
    text: "Kuwait",
  },
  {
    id: 118,
    Value: "Kyrgyzstan",
    text: "Kyrgyzstan",
  },
  {
    id: 119,
    Value: "Laos",
    text: "Laos",
  },
  {
    id: 120,
    Value: "Latvia",
    text: "Latvia",
  },
  {
    id: 121,
    Value: "Lebanon",
    text: "Lebanon",
  },
  {
    id: 122,
    Value: "Lesotho",
    text: "Lesotho",
  },
  {
    id: 123,
    Value: "Liberia",
    text: "Liberia",
  },
  {
    id: 124,
    Value: "Libya",
    text: "Libya",
  },
  {
    id: 125,
    Value: "Liechtenstein",
    text: "Liechtenstein",
  },
  {
    id: 126,
    Value: "Lithuania",
    text: "Lithuania",
  },
  {
    id: 127,
    Value: "Luxembourg",
    text: "Luxembourg",
  },
  // {
  //   id: 128,
  //   Value: "Macau S.A.R.",
  //   text: "Macau S.A.R.",
  // },
  {
    id: 129,
    Value: "Macedonia",
    text: "Macedonia",
  },
  {
    id: 130,
    Value: "Madagascar",
    text: "Madagascar",
  },
  {
    id: 131,
    Value: "Malawi",
    text: "Malawi",
  },
  {
    id: 132,
    Value: "Malaysia",
    text: "Malaysia",
  },
  {
    id: 133,
    Value: "Maldives",
    text: "Maldives",
  },
  {
    id: 134,
    Value: "Mali",
    text: "Mali",
  },
  {
    id: 135,
    Value: "Malta",
    text: "Malta",
  },
  // {
  //   id: 136,
  //   Value: "Man (Isle of)",
  //   text: "Man (Isle of)",
  // },
  // {
  //   id: 137,
  //   Value: "Marshall Islands",
  //   text: "Marshall Islands",
  // },
  // {
  //   id: 138,
  //   Value: "Martinique",
  //   text: "Martinique",
  // },
  {
    id: 139,
    Value: "Mauritania",
    text: "Mauritania",
  },
  {
    id: 140,
    Value: "Mauritius",
    text: "Mauritius",
  },
  // {
  //   id: 141,
  //   Value: "Mayotte",
  //   text: "Mayotte",
  // },
  // {
  //   id: 142,
  //   Value: "Mexico",
  //   text: "Mexico",
  // },
  // {
  //   id: 143,
  //   Value: "Almaty Region",
  //   text: "Almaty Region",
  // },
  // {
  //   id: 144,
  //   Value: "North Kazakhstan Region",
  //   text: "North Kazakhstan Region",
  // },
  // {
  //   id: 145,
  //   Value: "Monaco",
  //   text: "Monaco",
  // },
  {
    id: 146,
    Value: "Mongolia",
    text: "Mongolia",
  },
  {
    id: 147,
    Value: "Montenegro",
    text: "Montenegro",
  },
  // {
  //   id: 148,
  //   Value: "West Kazakhstan Province",
  //   text: "West Kazakhstan Province",
  // },
  // {
  //   id: 149,
  //   Value: "Turkestan Region",
  //   text: "Turkestan Region",
  // },
  {
    id: 150,
    Value: "Mozambique",
    text: "Mozambique",
  },
  {
    id: 151,
    Value: "Myanmar",
    text: "Myanmar",
  },
  {
    id: 152,
    Value: "Namibia",
    text: "Namibia",
  },
  {
    id: 153,
    Value: "Nauru",
    text: "Nauru",
  },
  {
    id: 154,
    Value: "Nepal",
    text: "Nepal",
  },
  {
    id: 155,
    Value: "Bonaire, Sint Eustatius and Saba",
    text: "Bonaire, Sint Eustatius and Saba",
  },
  {
    id: 156,
    Value: "Netherlands",
    text: "Netherlands",
  },
  // {
  //   id: 157,
  //   Value: "New Caledonia",
  //   text: "New Caledonia",
  // },
  {
    id: 158,
    Value: "New Zealand",
    text: "New Zealand",
  },
  {
    id: 159,
    Value: "Nicaragua",
    text: "Nicaragua",
  },
  {
    id: 160,
    Value: "Niger",
    text: "Niger",
  },
  {
    id: 161,
    Value: "Nigeria",
    text: "Nigeria",
  },
  // {
  //   id: 162,
  //   Value: "Niue",
  //   text: "Niue",
  // },
  // {
  //   id: 163,
  //   Value: "Norfolk Island",
  //   text: "Norfolk Island",
  // },
  // {
  //   id: 164,
  //   Value: "Northern Mariana Islands",
  //   text: "Northern Mariana Islands",
  // },
  {
    id: 165,
    Value: "Norway",
    text: "Norway",
  },
  {
    id: 166,
    Value: "Oman",
    text: "Oman",
  },
  {
    id: 167,
    Value: "Pakistan",
    text: "Pakistan",
  },
  {
    id: 168,
    Value: "Palau",
    text: "Palau",
  },
  // {
  //   id: 169,
  //   Value: "Palestinian Territory Occupied",
  //   text: "Palestinian Territory Occupied",
  // },
  {
    id: 170,
    Value: "Panama",
    text: "Panama",
  },
  {
    id: 171,
    Value: "Papua new Guinea",
    text: "Papua new Guinea",
  },
  {
    id: 172,
    Value: "Paraguay",
    text: "Paraguay",
  },
  {
    id: 173,
    Value: "Peru",
    text: "Peru",
  },
  {
    id: 174,
    Value: "Philippines",
    text: "Philippines",
  },
  // {
  //   id: 175,
  //   Value: "Pitcairn Island",
  //   text: "Pitcairn Island",
  // },
  // {
  //   id: 176,
  //   Value: "Poland",
  //   text: "Poland",
  // },
  {
    id: 177,
    Value: "Portugal",
    text: "Portugal",
  },
  // {
  //   id: 178,
  //   Value: "Puerto Rico",
  //   text: "Puerto Rico",
  // },
  {
    id: 179,
    Value: "Qatar",
    text: "Qatar",
  },
  // {
  //   id: 180,
  //   Value: "Reunion",
  //   text: "Reunion",
  // },
  // {
  //   id: 181,
  //   Value: "Romania",
  //   text: "Romania",
  // },
  {
    id: 182,
    Value: "Russia",
    text: "Russia",
  },
  {
    id: 183,
    Value: "Rwanda",
    text: "Rwanda",
  },
  // {
  //   id: 184,
  //   Value: "Saint Helena",
  //   text: "Saint Helena",
  // },
  {
    id: 185,
    Value: "Saint Kitts And Nevis",
    text: "Saint Kitts And Nevis",
  },
  {
    id: 186,
    Value: "Saint Lucia",
    text: "Saint Lucia",
  },
  // {
  //   id: 187,
  //   Value: "Saint Pierre and Miquelon",
  //   text: "Saint Pierre and Miquelon",
  // },
  {
    id: 188,
    Value: "Saint Vincent And The Grenadines",
    text: "Saint Vincent And The Grenadines",
  },
  // {
  //   id: 189,
  //   Value: "Saint-Barthelemy",
  //   text: "Saint-Barthelemy",
  // },
  // {
  //   id: 190,
  //   Value: "Saint-Martin (French part)",
  //   text: "Saint-Martin (French part)",
  // },
  {
    id: 191,
    Value: "Samoa",
    text: "Samoa",
  },
  {
    id: 192,
    Value: "San Marino",
    text: "San Marino",
  },
  {
    id: 193,
    Value: "Sao Tome and Principe",
    text: "Sao Tome and Principe",
  },
  {
    id: 194,
    Value: "Saudi Arabia",
    text: "Saudi Arabia",
  },
  {
    id: 195,
    Value: "Senegal",
    text: "Senegal",
  },
  {
    id: 196,
    Value: "Serbia",
    text: "Serbia",
  },
  {
    id: 197,
    Value: "Seychelles",
    text: "Seychelles",
  },
  {
    id: 198,
    Value: "Sierra Leone",
    text: "Sierra Leone",
  },
  // {
  //   id: 199,
  //   Value: "Singapore",
  //   text: "Singapore",
  // },
  {
    id: 200,
    Value: "Slovakia",
    text: "Slovakia",
  },
  {
    id: 201,
    Value: "Slovenia",
    text: "Slovenia",
  },
  {
    id: 202,
    Value: "Solomon Islands",
    text: "Solomon Islands",
  },
  {
    id: 203,
    Value: "Somalia",
    text: "Somalia",
  },
  {
    id: 204,
    Value: "South Africa",
    text: "South Africa",
  },
  // {
  //   id: 205,
  //   Value: "South Georgia",
  //   text: "South Georgia",
  // },
  // {
  //   id: 206,
  //   Value: "South Sudan",
  //   text: "South Sudan",
  // },
  // {
  //   id: 207,
  //   Value: "Spain",
  //   text: "Spain",
  // },
  {
    id: 208,
    Value: "Sri Lanka",
    text: "Sri Lanka",
  },
  {
    id: 209,
    Value: "Sudan",
    text: "Sudan",
  },
  {
    id: 210,
    Value: "Suriname",
    text: "Suriname",
  },
  // {
  //   id: 211,
  //   Value: "Svalbard And Jan Mayen Islands",
  //   text: "Svalbard And Jan Mayen Islands",
  // },
  {
    id: 212,
    Value: "Swaziland",
    text: "Swaziland",
  },
  {
    id: 213,
    Value: "Sweden",
    text: "Sweden",
  },
  {
    id: 214,
    Value: "Switzerland",
    text: "Switzerland",
  },
  {
    id: 215,
    Value: "Syria",
    text: "Syria",
  },
  {
    id: 216,
    Value: "Taiwan",
    text: "Taiwan",
  },
  {
    id: 217,
    Value: "Tajikistan",
    text: "Tajikistan",
  },
  {
    id: 218,
    Value: "Tanzania",
    text: "Tanzania",
  },
  {
    id: 219,
    Value: "Thailand",
    text: "Thailand",
  },
  {
    id: 220,
    Value: "Togo",
    text: "Togo",
  },
  // {
  //   id: 221,
  //   Value: "Tokelau",
  //   text: "Tokelau",
  // },
  {
    id: 222,
    Value: "Tonga",
    text: "Tonga",
  },
  {
    id: 223,
    Value: "Trinidad And Tobago",
    text: "Trinidad And Tobago",
  },
  {
    id: 224,
    Value: "Tunisia",
    text: "Tunisia",
  },
  {
    id: 225,
    Value: "Turkey",
    text: "Turkey",
  },
  {
    id: 226,
    Value: "Turkmenistan",
    text: "Turkmenistan",
  },
  // {
  //   id: 227,
  //   Value: "Turks And Caicos Islands",
  //   text: "Turks And Caicos Islands",
  // },
  {
    id: 228,
    Value: "Tuvalu",
    text: "Tuvalu",
  },
  {
    id: 229,
    Value: "Uganda",
    text: "Uganda",
  },
  {
    id: 230,
    Value: "Ukraine",
    text: "Ukraine",
  },
  {
    id: 231,
    Value: "United Arab Emirates",
    text: "United Arab Emirates",
  },
  {
    id: 232,
    Value: "United Kingdom",
    text: "United Kingdom",
  },
  {
    id: 233,
    Value: "United States",
    text: "United States",
  },
  // {
  //   id: 234,
  //   Value: "United States Minor Outlying Islands",
  //   text: "United States Minor Outlying Islands",
  // },
  {
    id: 235,
    Value: "Uruguay",
    text: "Uruguay",
  },
  {
    id: 236,
    Value: "Uzbekistan",
    text: "Uzbekistan",
  },
  {
    id: 237,
    Value: "Vanuatu",
    text: "Vanuatu",
  },
  // {
  //   id: 238,
  //   Value: "Vatican City State (Holy See)",
  //   text: "Vatican City State (Holy See)",
  // },
  {
    id: 239,
    Value: "Venezuela",
    text: "Venezuela",
  },
  {
    id: 240,
    Value: "Vietnam",
    text: "Vietnam",
  },
  // {
  //   id: 241,
  //   Value: "Virgin Islands (British)",
  //   text: "Virgin Islands (British)",
  // },
  // {
  //   id: 242,
  //   Value: "Virgin Islands (US)",
  //   text: "Virgin Islands (US)",
  // },
  // {
  //   id: 243,
  //   Value: "Wallis And Futuna Islands",
  //   text: "Wallis And Futuna Islands",
  // },
  // {
  //   id: 244,
  //   Value: "Western Sahara",
  //   text: "Western Sahara",
  // },
  {
    id: 245,
    Value: "Yemen",
    text: "Yemen",
  },
  {
    id: 246,
    Value: "Zambia",
    text: "Zambia",
  },
  {
    id: 247,
    Value: "Zimbabwe",
    text: "Zimbabwe",
  },
  // {
  //   id: 248,
  //   Value: "Kosovo",
  //   text: "Kosovo",
  // },
  // {
  //   id: 249,
  //   Value: "Curaçao",
  //   text: "Curaçao",
  // },
  // {
  //   id: 250,
  //   Value: "Sint Maarten (Dutch part)",
  //   text: "Sint Maarten (Dutch part)",
  // },
];
