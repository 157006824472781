import React from "react";
import { FC } from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorMessage, useField, useFormikContext } from "formik";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import infoerror from "src/assets/register/infoerror.svg";
import uuid from "uuid";
import { Label } from "@mui/icons-material";
interface formProps {
  label?: any;
  name: string;
  options?: any;
  type?: string;
  hint?: string;
  state?: any;
  setState?: any;
  size?: any;
  onClick?: any;
  notRequired?: any;
  dropdownPaddingBottom?: any;
  dropdownPaddingTop?: any;
  onChange?: any;
  defaultValue?: string;
}

const AutoCompleteSelect: FC<formProps> = ({
  defaultValue,
  name,
  options,
  label,
  state,
  setState,
  size,
  notRequired,
  dropdownPaddingBottom,
  dropdownPaddingTop,
  onChange,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const handleChange = (event: any, newValue: any) => {
    {
      setFieldValue(name, newValue === null ? "" : newValue);
      onChange && onChange(newValue === null ? "" : newValue);
    }
  };
  const configSelect = { onChange: handleChange, ...otherProps };
  const index = 0;
  const optionVal = options.map((item: any) => {
    let { Value, city_name, Postcode, text, value, JobRole } = item;
    return Value || city_name || Postcode || text || value || JobRole;
  });
  const uniqueValue = optionVal.filter((val: any, id: any, array: any) => {
    return array.indexOf(val) == id;
  });

  return (
    <Box position="relative" className="form">
      <FormControl
        fullWidth
        size={size}
        error={!!(meta.touched && meta.error)}
        sx={{ textAlign: "start" }}
      >
        <Autocomplete
          disablePortal
          defaultValue={state}
          id={label}
          popupIcon={
            <KeyboardArrowDownIcon
              sx={{ color: "#625e66", fontSize: "2.5rem" }}
            />
          }
          options={uniqueValue}
          isOptionEqualToValue={(option: any, value: any) => {
            if (value === "") {
              return option;
            } else if (value === option) {
              return true;
            }
          }}
          renderOption={(props, option) => {
            return (
              <span
                key={index + 1}
                {...props}
                style={{ backgroundColor: "#fff" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    transition: "all 0.25s ease-in-out",
                    py: 0.6,
                    "&:hover": {
                      backgroundColor: "#edfffa",
                      paddingLeft: "20px",
                      fontWeight: 600,
                    },
                  }}
                  key={index + 1}
                >
                  {option}
                </Box>
              </span>
            );
          }}
          {...configSelect}
          renderInput={(params) => (
            <TextField
              error={!!(meta.touched && meta.error)}
              {...params}
              label={
                <label>
                  {label}{" "}
                  {!notRequired && <span style={{ color: "#ff3333" }}>*</span>}
                </label>
              }
            />
          )}
          sx={{
            "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
              color: "#625e66",
            },
            "& .MuiAutocomplete-endAdornment": { top: 7 },
          }}
        />
      </FormControl>

      {meta.touched && meta.error ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            position: "absolute",
            right: "5px",
          }}
        >
          <ErrorMessage
            component="div"
            name={field.name}
            className="error font-size-12 font-weight-500 dark-washed-red font-family-poppins"
          />
          <Box sx={{ ml: 1 }}>
            <img src={infoerror} alt="info" />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default AutoCompleteSelect;
