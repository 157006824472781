export const typography = {
  fontFamilyPoppins: "Poppins",
  fontFamilyRoboto: "Roboto",
  fontStyle: "normal",
  lineHeight: 1.2,
  htmlFontSize: 16,
  fontSize: 14,
  fontWeight300: 300,
  fontWeight400: 400,
  fontWeight500: 500,
  fontWeight600: 600,
  fontWeight700: 700,
  fontWeight800: 800,

  // For Profile Heading
  h1: {
    fontWeight: 600,
    fontSize: 28,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    fontStyle: "normal",
  },
  // For Profile Heading End

  // For Main Layout Heading
  h2: {
    fontWeight: 600,
    fontSize: 20,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    fontStyle: "normal",
  },
  // For Main Layout Heading End

  // For Table Heading
  h3: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.2,
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  // For Table Heading End

  // For Authentication Heading
  h4: {
    fontWeight: 600,
    fontSize: 22,
    letterSpacing: "0.36px",
    lineHeight: 1.2,
    fontStyle: "normal",
  },
  // For Authentication Heading End

  // Table Filter Text
  subtitle2: {
    fontWeight: 500,
    fontSize: 12,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    fontStyle: "normal",
  },
  // Table Filter Text End

  button: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: "Poppins",
    lineHeight: 1.2,
    textTransform: "capitalize",
  },

  // For Top Banner Heading Text
  body1: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  // For Top Banner Heading Text end

  // For Main Text
  body2: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  // For Main Text end

  caption: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },

  overline: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 2.66,
    letterSpacing: "0.08333em",
    textTransform: "capitalize",
  },
};
